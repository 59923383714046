import { Suspense } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { getAppConfig } from '@bts-web/core-app-config';
import { BitPandaLogoIcon } from '../../../common/svgIcons/BitPandaLogoIcon/BitPandaLogoIcon';
import { ReadOnlyTag } from '../../../common/components/ReadOnlyTag/ReadOnlyTag';
import {
  MobileLanguageDrawer,
  type MobileLanguageDrawerTranslations,
} from '../MobileLanguageDrawer/MobileLanguageDrawer';
import { MobileProfileMenu } from '../MobileProfileMenu/MobileProfileMenu';
import { ReportsAndStatementsNotifications } from '../Reusable/ReportsAndStatementsNotifications/ReportsAndStatementsNotifications';

export const mobileHeaderWrapperStyles = css({
  backgroundColor: 'screen_background.primary',
  display: 'flex',
  justifyContent: 'space-between',
  py: 'extra_small',
  paddingInlineStart: 'medium',
  paddingInlineEnd: 'medium',
  alignItems: 'center',
});

const MobileHeader = ({
  translations,
}: {
  translations: MobileLanguageDrawerTranslations;
}): React.ReactElement => {
  const { feature_showNotifications } = getAppConfig();

  return (
    <header className={mobileHeaderWrapperStyles}>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: 'medium',
        })}
      >
        <BitPandaLogoIcon size={20} />

        <ReadOnlyTag />
      </div>

      <div
        className={css({
          display: 'flex',
          gap: 'small',
        })}
      >
        <Suspense fallback={null}>
          <MobileProfileMenu />
        </Suspense>

        <MobileLanguageDrawer translations={translations} />

        {feature_showNotifications && (
          <div className={css({ position: 'relative', top: '-0.5px ' })}>
            {feature_showNotifications && <ReportsAndStatementsNotifications />}
          </div>
        )}
      </div>
    </header>
  );
};

export { MobileHeader };
