'use client';

import React, { forwardRef } from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import { Icon } from '@bts-web/design-system/component/icon';
import { Z_INDEX_LIBRARY, sva } from '@bts-web/utils-style-engine';
import type { RecipeVariantProps } from '@bts-web/utils-style-engine';

export interface OptionItem {
  label: string;
  value: string;
  disabled?: boolean;
}

const selectStyles = sva({
  slots: [
    'root',
    'trigger',
    'triggerPlaceholder',
    'icon',
    'content',
    'viewport',
    'item',
    'itemText',
    'itemIndicator',
    'label',
    'separator',
    'scrollButton',
    'itemDisabled',
    'itemHighlighted',
    'baseIndicator',
  ],
  base: {
    root: {
      position: 'relative',
    },
    trigger: {
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '4px',
      padding: '0 15px',
      fontSize: '13px',
      lineHeight: '1',
      height: '35px',
      gap: '5px',
      backgroundColor: 'text_primary_inverted',
      color: 'neutrals.text_primary',
      width: '100%',
      whiteSpace: 'nowrap',
    },
    triggerPlaceholder: {
      color: 'neutrals.text_secondary',
    },
    icon: {
      color: 'neutrals.text_primary',
      pointerEvents: 'none',
      paddingInlineStart: 'extra_small_4',
    },
    content: {
      backgroundColor: 'neutrals.card_fill_primary',
      overflow: 'hidden',
      borderRadius: '6px',
    },
    viewport: {
      padding: '5px',
    },
    item: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 'small',
      whiteSpace: 'nowrap',
      padding: 'extra_small',
      color: 'neutrals.fill_primary',
      textDecoration: 'none',
      cursor: 'pointer',
      backgroundColor: 'neutrals.card_fill_primary',
      _hover: {
        backgroundColor: 'neutrals.fill_quaternary',
      },
      _disabled: {
        userSelect: 'neutrals.card_fill_primary',
        cursor: 'not-allowed',
        color: 'neutrals.text_disabled',
      },
    },
    itemDisabled: {
      color: 'neutrals.text_disabled',
      pointerEvents: 'none',
      backgroundColor: 'neutrals.fill_quinary',
      opacity: '0.5',
    },
    itemHighlighted: {
      outline: 'none',
      backgroundColor: 'neutrals.fill_secondary',
      color: 'neutrals.text_primary_inverted',
    },
    itemText: {
      fontSize: '14px',
      color: 'red',
    },
    itemIndicator: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingInlineStart: 'extra_small_4',
    },
    label: {
      padding: '0 25px',
      fontSize: '12px',
      lineHeight: '25px',
      color: 'neutrals.text_secondary',
    },
    separator: {
      height: '1px',
      backgroundColor: 'neutrals.divider',
      margin: '5px',
    },
    scrollButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '25px',
      backgroundColor: 'white',
      color: 'neutrals.text_primary',
      cursor: 'default',
    },
  },
  variants: {
    visual: {
      primary: {},
      filterPrimary: {
        trigger: {
          color: 'neutrals.text_primary',
          backgroundColor: 'neutrals.fill_quaternary',
          borderRadius: '30px',
          height: '40px',
          padding: 'extra_small_2',
        },
      },
    },
  },
  defaultVariants: {
    visual: 'primary',
  },
});

type SelectStyleProps = RecipeVariantProps<typeof selectStyles>;

type SelectProps = SelectStyleProps & {
  options: OptionItem[];
  iconName?: string;
  noDropdownIcon?: boolean;
  disabled?: boolean;
  placeholder?: string | React.ReactElement;
  value?: string;
  defaultValue?: string;
  onChange(value: string, option: OptionItem | undefined): void;
};

const SelectItem = forwardRef<HTMLDivElement, RadixSelect.SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    const styles = selectStyles();

    return (
      <RadixSelect.Item
        className={`${styles.item} ${props.disabled ? styles.itemDisabled : ''}`}
        {...props}
        ref={forwardedRef}
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        <RadixSelect.ItemText className={styles.itemText}>
          {children}
        </RadixSelect.ItemText>
        <RadixSelect.ItemIndicator className={styles.itemIndicator}>
          <Icon icon="checkmark" size="16" />
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  },
);

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      options,
      iconName = 'chevron-down',
      noDropdownIcon = false,
      disabled,
      placeholder,
      value,
      defaultValue,
      onChange,
      visual = 'primary',
      ...props
    },
    forwardedRef,
  ) => {
    const styles = selectStyles({ visual });

    return (
      <RadixSelect.Root
        value={value}
        defaultValue={defaultValue}
        onValueChange={(newValue) => {
          const option = options.find((opt) => opt.value === newValue);

          onChange(newValue, option);
        }}
        disabled={disabled}
      >
        <RadixSelect.Trigger
          className={`${styles.trigger} ${disabled ? styles.itemDisabled : ''}`}
          style={{
            whiteSpace: 'nowrap',
          }}
          ref={forwardedRef}
        >
          <RadixSelect.Value placeholder={placeholder} />
          {!noDropdownIcon && (
            <RadixSelect.Icon className={styles.icon}>
              <Icon icon={iconName} size="16" />
            </RadixSelect.Icon>
          )}
        </RadixSelect.Trigger>

        <RadixSelect.Portal>
          <RadixSelect.Content
            className={styles.content}
            style={{
              zIndex: Z_INDEX_LIBRARY.DROPDOWN,
              boxShadow:
                '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
            }}
            onPointerDown={(e) => e.stopPropagation()}
            updatePositionStrategy="always"
            position="popper"
          >
            <RadixSelect.ScrollUpButton className={styles.scrollButton}>
              <Icon icon="chevron-up" size="16" />
            </RadixSelect.ScrollUpButton>
            <RadixSelect.Viewport className={styles.viewport}>
              <RadixSelect.Group>
                {options.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </RadixSelect.Group>
            </RadixSelect.Viewport>
            <RadixSelect.ScrollDownButton className={styles.scrollButton}>
              <Icon icon="chevron-down" size="16" />
            </RadixSelect.ScrollDownButton>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    );
  },
);
