'use client';

import { ReactElement, cloneElement, FC } from 'react';
import { useComposableModal } from '@bts-web/utils-context';
import { ButtonComponentProps } from '../../types';
import { UserAccessModal } from '../../components/composableModals';
import { useClientUserInfo } from '../../userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';

interface UserAccessWithRoleCheckProps {
  children: ReactElement<ButtonComponentProps>;
}

export const UserAccessWithRoleCheck: FC<UserAccessWithRoleCheckProps> = ({
  children,
}) => {
  const userData = useClientUserInfo();

  const { showModal } = useComposableModal();

  const { isReadOnlyUser, approved, tradingActive, userType } = userData;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();

    if (!approved && userType === 'BusinessUser') {
      showModal(UserAccessModal, {
        type: 'infoApprovalPending',
      });

      return;
    }

    if (!tradingActive) {
      showModal(UserAccessModal, {
        type: 'warningTrade',
      });

      return;
    }

    if (isReadOnlyUser) {
      showModal(UserAccessModal, {
        type: 'readOnly',
      });
    }
  };

  if (
    isReadOnlyUser ||
    (!approved && userType === 'BusinessUser') ||
    !tradingActive
  ) {
    return cloneElement(children, {
      onClick: handleClick,
    });
  }

  return children;
};
