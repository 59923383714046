import { FC, useMemo } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import { ProgressIndicatorWithSteps } from '../../../../../common/components/ProgressIndicatorWithSteps/ProgressIndicatorWithSteps';
import {
  TransfersDepositStepsIds,
  TransfersCommonStepsIds,
} from '../../../../types';

interface DepositProgressBarProps {
  state: TTransfersNextStepStateWithActions;
}

const DepositProgressBar: FC<DepositProgressBarProps> = ({ state }) => {
  const currentStep = useMemo(() => {
    let currentStep = 0;

    if (state.selectedAssetData) {
      currentStep = 0;
    }

    if (state.pageId === TransfersCommonStepsIds.ChooseNetwork) {
      currentStep = 1;
    }

    if (state.pageId === TransfersCommonStepsIds.AssetAndNetworkSummary) {
      currentStep = 2;
    }

    if (state.pageId === TransfersDepositStepsIds.DepositWarning) {
      currentStep = 3;
    }

    if (state.pageId === TransfersDepositStepsIds.DepositWallet) {
      currentStep = 4;
    }

    return currentStep;
  }, [state]);

  return (
    <div className={css({ padding: 'medium' })}>
      <ProgressIndicatorWithSteps totalSteps={5} currentStep={currentStep} />
    </div>
  );
};

export { DepositProgressBar };
