import type { PrecisionFloat } from '@bts-web/utils-formatting';
import { Locale } from '@bts-web/utils-lokalise';
import { AmountFor } from '@bts-web/data-layer/server';
import {
  valueFromAssetToFiat,
  valueFromFiatToAsset,
  getAssetDisplayValueFromSource,
} from '../../../common';
import type { MaximumTradeAmountType, MutableNonNullableAsset } from '../types';

export type IConvertedSummaryProps = {
  value: string;
  displayType: AmountFor;
  locale: Locale;
  currency: string;
  symbol: MutableNonNullableAsset['symbol'];
  translations?: {
    inclSpread: string;
  };
  assetPrice: PrecisionFloat;
  // to be used in the Accept Offer context because the value calculated by whitelabel, that we get as total is different from the one that we get if we calculate, because the value of assetPrice used by whitelabel is different than the one that we get, so we directly display the asset value that we get from the offer itself instead of calculating it
  // our calculations are also applying some rounding and we are not sure if the whitelabel is doing the same
  useRawSource?: {
    rawSourceValue: PrecisionFloat;
    rawSourceDisplayType: AmountFor;
  };
  maximumTradeAmount?: MaximumTradeAmountType;
  showInclSpread?: boolean;
};

const getOppositeDisplayType = (displayType: AmountFor) =>
  displayType === 'FIAT' ? 'ASSET' : 'FIAT';

export const getConvertedSummary = ({
  assetPrice,
  currency,
  displayType,
  locale,
  symbol,
  translations,
  value,
  useRawSource,
  maximumTradeAmount,
  showInclSpread,
}: IConvertedSummaryProps) => {
  const isFiat = displayType === 'FIAT';

  // Check if useRawSource is provided and use its values if available
  const workingValue =
    useRawSource && useRawSource?.rawSourceValue?.value
      ? useRawSource.rawSourceValue.value
      : value.replace(/,/g, '.');

  const sourceDisplayType = useRawSource
    ? useRawSource.rawSourceDisplayType
    : getOppositeDisplayType(displayType);

  let convertionAmount = useRawSource
    ? useRawSource.rawSourceValue
    : isFiat
      ? valueFromFiatToAsset(workingValue, assetPrice)
      : valueFromAssetToFiat(workingValue, assetPrice);

  // for maximum ASSET trade values, show the maximumFiatTradeAmount as the conversion value

  if (maximumTradeAmount) {
    if (!isFiat && workingValue === maximumTradeAmount?.ASSET.value) {
      convertionAmount = maximumTradeAmount.FIAT;
    } // for maximum FIAT trade values, show the maximumAssetTradeAmount as the conversion value
    else if (isFiat && workingValue === maximumTradeAmount?.FIAT.value) {
      convertionAmount = maximumTradeAmount.ASSET;
    }
  }

  // symbol might come null
  const symbolStringElement: string = symbol ?? '';

  const label = `≈ ${getAssetDisplayValueFromSource({
    sourceAmount: convertionAmount?.value ?? '',
    sourceType: sourceDisplayType,
    currency,
    locale,
    customSymbol: symbolStringElement,
  })}`;

  const labelWithSpread = `${label} ${translations && translations.inclSpread ? translations.inclSpread : '-- missing translation --'}`;

  return showInclSpread ? labelWithSpread : label;
};
