import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';

export function EntryRightIcon({ icon }: { icon: string }) {
  return (
    <div
      className={css({
        p: 'extra_small_2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginInlineStart: 'auto',
      })}
    >
      <Icon size="20" icon={icon} />
    </div>
  );
}
