import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';

export interface CheckboxToggleProps {
  size?: 'small' | 'medium';
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export interface CheckboxToggleProps {
  size?: 'small' | 'medium';
  variant?: 'primary' | 'positive';
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export const CheckboxToggle: FC<CheckboxToggleProps> = ({
  size = 'medium',
  variant = 'primary',
  checked,
  onChange,
  disabled,
}) => {
  return (
    <label
      aria-label="checkbox toggle"
      data-state={checked ? 'active' : 'inactive'}
      className={css({
        position: 'relative',
        display: 'inline-block',
        ...(size === 'small'
          ? {
              width: '40px',
              height: '24px',
              minWidth: '40px',
              minHeight: '24px',
            }
          : {
              width: '51px',
              height: '30px',
              minWidth: '51px',
              minHeight: '30px',
            }),
        ...(checked
          ? {
              ...(variant === 'positive'
                ? {
                    '& span': {
                      backgroundColor: 'positive.fill_primary',
                    },
                  }
                : {}),

              ...(variant === 'primary'
                ? {
                    '& span': {
                      backgroundColor: 'neutrals.fill_primary',
                    },
                  }
                : {}),

              ...(size === 'small'
                ? {
                    '& span:before': {
                      transform: 'translateX(16px)',
                    },
                  }
                : {
                    '& span:before': {
                      transform: 'translateX(20px)',
                    },
                  }),
            }
          : disabled
            ? {
                '& span': {
                  backgroundColor: 'neutrals.fill_tertiary',
                },
              }
            : {
                '& span': {
                  backgroundColor: 'neutrals.fill_secondary',
                },
              }),
        ...(size === 'small'
          ? {
              width: '36px',
              height: '20px',
              minWidth: '36px',
              minHeight: '20px',
            }
          : {
              width: '51px',
              height: '31px',
              minWidth: '51px',
              minHeight: '31px',
            }),
      })}
    >
      <input
        type="checkbox"
        onChange={() => {
          onChange();
        }}
        className={css({
          opacity: 0,
          width: 0,
          height: 0,
        })}
        checked={checked}
        disabled={disabled}
      />

      <span
        className={css({
          position: 'absolute',
          cursor: 'pointer',
          top: 0,
          insetInlineStart: 0,
          insetInlineEnd: 0,
          bottom: 0,
          transition: '.4s',
          ...(size === 'small'
            ? { borderRadius: '44px' }
            : { borderRadius: '34px' }),
          '&::before': {
            position: 'absolute',
            content: '""',
            ...(size === 'small'
              ? {
                  width: '20px',
                  height: '20px',
                }
              : {
                  width: '26px',
                  height: '26px',
                }),
            insetInlineStart: '2px',
            ...(size === 'small'
              ? { height: '16px', width: '16px' }
              : { height: '26px', width: '26px' }),
            bottom: '2px',
            backgroundColor: 'neutrals.card_fill_primary',
            transition: '.4s',
            borderRadius: '50%',
          },
        })}
      />
    </label>
  );
};
