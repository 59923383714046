import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  useId,
} from 'react';
import { css } from '@bts-web/utils-style-engine';
import { getInputClassNameByAmountLength } from './getInputClassNameByAmountLength';

type AmountInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  value: string;
  currentLocale: string;
  placeholder?: HTMLInputElement['placeholder'];
};

export const amountInputWrapperStyles = css({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  '& .cursorPointer': {
    cursor: 'pointer',
    minWidth: '0',
    textAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& .placeHolderFiller': {
    color: 'neutrals.fill_quinary',
  },
  '& input, & > div': {
    width: '100%',
    height: '46px',
    color: 'neutrals.text_primary',
    caretColor: 'neutrals.text_primary',
    '&::placeholder': {
      color: 'neutrals.fill_quinary',
    },

    _focus: {
      border: 'none',
      outline: 'none',
    },

    _disabled: {
      background: 'none',
    },
  },
  '& .large-input-font': {
    fontWeight: 'display.medium',
    letterSpacing: 'display.medium',
    lineHeight: 'display.medium',
    fontSize: { base: '1.6rem', lg: 'display.medium' },
  },
  '& .medium-input-font': {
    fontSize: 'headline.large',
    fontWeight: 'headline.large',
    letterSpacing: 'headline.large',
    lineHeight: 'headline.large',
  },
  '& .small-input-font': {
    fontSize: 'headline.medium',
    fontWeight: 'headline.medium',
    letterSpacing: 'headline.medium',
    lineHeight: 'headline.medium',
  },
  '& .ellipsis-input-font': {
    fontSize: 'headline.medium',
    fontWeight: 'headline.medium',
    letterSpacing: 'headline.medium',
    lineHeight: 'headline.medium',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

export const AmountInput = forwardRef<HTMLInputElement, AmountInputProps>(
  ({ onBlur, onChange, onFocus, value, currentLocale, placeholder }, ref) => {
    const inputClassName = getInputClassNameByAmountLength(value);

    return (
      <div className={amountInputWrapperStyles}>
        <input
          autoComplete="off"
          aria-label="amount input field"
          data-testid="special-input-field__input"
          ref={ref}
          data-element="input"
          placeholder={placeholder}
          id={`amount-${useId()}`}
          type="text"
          inputMode="decimal"
          value={value}
          lang={currentLocale}
          onChange={onChange}
          onBlur={(e) => onBlur?.(e.target.value)}
          onFocus={onFocus}
          className={inputClassName}
        />
      </div>
    );
  },
);
