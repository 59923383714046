'use client';

/*
  TODO show search suggestions for saved addresses (when it's supported)
 */
import { ChangeEvent, FC, useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { ButtonBase } from '../../../../common/components/ButtonBase/ButtonBase';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { DestinationTagValues, TransfersSendStepsIds } from '../../../types';
import { MainInput } from '../../../../common/components/MainInput/MainInput';
import { validateCryptoAddress } from '../actions/validateCryptoAddress.action';
import { useAppNotification } from '../../../../notifications/NotificationContextProvider';
import { datadogErrorHelper } from '../../../../common/utils/datadogErrorHelper';
import { getMemoType } from '../../../utils/getMemoType';
import { WalletsList } from './components/WalletsList';

interface ChooseRecipientScreenProps {
  locale: typeof defaultLocale;
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const labelStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  color: 'neutrals.text_secondary',
});

const inputWrapStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small_2',
});

const inputStyles = css({
  width: '100%',
  padding: '12px',
  fontSize: '15px',
  border: '1px solid',
  borderColor: 'neutrals.fill_tertiary',
  borderRadius: '4px',
  marginTop: '2px',
  '&:focus': {
    marginTop: '0',
    border: '2px solid',
    borderColor: 'neutrals.stroke_primary',
  },
  '&[data-invalid=true]': {
    borderColor: 'warning.stroke_primary',
  },
  '&[data-valid=true]': {
    borderColor: 'positive.stroke_primary',
  },
});

const ChooseRecipientScreen: FC<ChooseRecipientScreenProps> = ({
  state,
  locale,
  translations,
}) => {
  const { setAppNotification } = useAppNotification();

  const [loading, setLoading] = useState(false);

  const [walletAddressInputValue, setWalletAddressInputValue] = useState(
    state.recipientAddress,
  );

  const [memoInputValue, setMemoInputValue] = useState(state.recipientMemo);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const onWalletAddressInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const addressInputValue = e.target.value;

    const sanitizedValue = addressInputValue.replace(/[^a-zA-Z0-9]/g, '');

    setWalletAddressInputValue(sanitizedValue);

    state.setRecipientAddress(sanitizedValue);
  };

  const onMemoInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMemoInputValue(e.target.value);

    state.setRecipientMemo(e.target.value);
  };

  const onClickContinue = () => {
    setLoading(true);

    validateCryptoAddress({
      coinNetworkId: state.selectedNetworkData?.coinNetworkId as string,
      toAddress: walletAddressInputValue as string,
      // if destinationTag from network is empty, memo is not required
      ...(state.selectedNetworkData?.destinationTag && memoInputValue
        ? {
            destinationTag: memoInputValue as string,
            destinationTagType: getMemoType(
              memoInputValue,
              state.selectedAssetData?.symbol as string,
            ),
          }
        : {}),
    })
      .then((response) => {
        if (response.errors) {
          processErrors(response.errors);

          return;
        }

        const validateData = response.data.validateCryptoAddress;

        if (!validateData?.isAddressValid) {
          setAppNotification({
            title: translations.cryptoWithdrawalRecipientErrorTitle,
            subtitle: translations.cryptoWithdrawalRecipientErrorSubtitle,
            visual: 'error',
            position: 'bottomRight',
          });

          return;
        }

        if (validateData.isDestinationTagValid === false) {
          setAppNotification({
            title: translations.cryptoWithdrawalMemoErrorTitle,
            subtitle: translations.cryptoWithdrawalMemoErrorSubtitle,
            visual: 'warning',
            position: 'bottomRight',
          });

          return;
        }

        const showMissingMemoStep =
          state.selectedNetworkData?.destinationTag && !memoInputValue;

        state.changeToPageAndBindActions({
          pageId: showMissingMemoStep
            ? TransfersSendStepsIds.MemoMissingWarning
            : TransfersSendStepsIds.ChooseAmount,
          newBackAction: {
            pageId: TransfersSendStepsIds.ChooseRecipient,
            type: 'page',
          },
        });
      })
      .catch((error) => {
        setAppNotification({
          title: translations.generalErrorHeadline,
          visual: 'error',
          position: 'bottomRight',
        });

        datadogErrorHelper({
          errorMessage: `Could not validate input with address value: ${walletAddressInputValue} and memo value: ${memoInputValue}`,
          errorSeverity: DatadogErrorLevels.CRITICAL,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const canContinue = Boolean(walletAddressInputValue);

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        flex: 1,
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 'small',
        })}
      >
        <div className={inputWrapStyles}>
          <label className={labelStyles} htmlFor="wallet-address">
            {translations.walletAddress}
          </label>

          <MainInput
            className={inputStyles}
            onChange={onWalletAddressInputChange}
            locale={locale}
            value={walletAddressInputValue || ''}
            id="wallet-address"
          />
        </div>

        {/* Show second input if network destinationTag(memo/destination_tag/message) is provided */}
        {state.selectedNetworkData?.destinationTag && (
          <div className={inputWrapStyles}>
            <label
              className={labelStyles}
              htmlFor={state.selectedNetworkData.destinationTag}
            >
              {
                translations[
                  state.selectedNetworkData
                    .destinationTag as DestinationTagValues
                ]
              }
            </label>

            <MainInput
              className={inputStyles}
              onChange={onMemoInputChange}
              locale={locale}
              value={memoInputValue || ''}
              id={state.selectedNetworkData.destinationTag}
            />
          </div>
        )}

        {false && ( //TODO: remove fake flag when API is ready
          <WalletsList
            items={[
              {
                id: '1',
                address: '421312esdas4213',
                name: 'Wallet 1',
                tag: 'TEST',
              },
              {
                id: '2',
                address: '412343412adfasr234e1234123',
                name: 'Wallet 2',
                tag: 'TEST',
              },
            ]}
            selectedItemId="1"
            translations={{ savedWallets: translations.savedWallets }}
            onChooseItem={(walletAddress) => console.log(walletAddress)}
          />
        )}
      </div>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        })}
      >
        <ButtonBase
          isLoading={loading}
          disabled={canContinue === false || loading}
          onClick={onClickContinue}
        >
          {translations.next}
        </ButtonBase>
      </div>
    </div>
  );
};

export { ChooseRecipientScreen };
