import {
  Language,
  Locale,
  getLocale,
  i18n,
  initServerTranslation,
} from '@bts-web/utils-lokalise';
import { getTradeTranslations } from '../utils/getTradeTranslations';
import { NavigationBackButton, NavigationButton } from '../../navigation/index';
import { NavigationBar } from '../../common';
import { TFunction } from 'i18next';
import { TradeScreenTranslations } from '../utils/getTradeTranslations';
import { PreAcceptSavingsPlanMutation$data } from '@bts-web/data-layer/server';

const { t } = initServerTranslation();

export type AcceptSavingsPlanControllerProps = {
  translationUtil: TFunction;
  savingsPlanData: NonNullable<
    PreAcceptSavingsPlanMutation$data['preAcceptSavingsPlan']
  >;
  children?: (props: {
    savingsPlanData: NonNullable<
      PreAcceptSavingsPlanMutation$data['preAcceptSavingsPlan']
    >;
    translations: TradeScreenTranslations;
    currentLocale: Locale;
  }) => React.ReactNode;
};

const AcceptSavingsPlanController = async ({
  savingsPlanData,
  children,
}: AcceptSavingsPlanControllerProps) => {
  const currentLocale = getLocale(i18n.resolvedLanguage as Language);

  const translations = getTradeTranslations(t as TFunction);

  return (
    <>
      <NavigationBar
        title={translations.savingsPlan}
        NavigationLeftSlot={<NavigationBackButton />}
        NavigationRightSlot={
          <NavigationButton icon="dismiss" to="/assets/portfolio" />
        }
      />

      {children &&
        children({
          savingsPlanData,
          translations,
          currentLocale,
        })}
    </>
  );
};

export { AcceptSavingsPlanController };
