'use client';

import { useComposableModal } from '@bts-web/utils-context';
import { ButtonBase } from '../../../../../common/components/ButtonBase/ButtonBase';
import { Drawer } from '../../../../Drawer/Drawer';
import { IMainNavsTranslations } from '../../../utils/getMainNavsTranslations';
import { useClientUserInfo } from '../../../../../common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';
import { UserAccessModal } from '../../../../../common/components/composableModals';

interface CryptoTransfersDrawerProps {
  contentNode: React.ReactNode;
  translatedValues: Pick<
    IMainNavsTranslations,
    | 'buttonTransferTopNav'
    | 'errorPendingApprovalTransfer'
    | 'errorTitleTransfersUnavailable'
    | 'goToPortfolio'
  >;
}

const CryptoTransfersDrawer = ({
  contentNode,
  translatedValues,
}: CryptoTransfersDrawerProps) => {
  const { withdrawalsActive, onboarded, approved, userType } =
    useClientUserInfo();

  const { showModal } = useComposableModal();

  if (!withdrawalsActive && userType === 'BusinessUser') {
    return (
      <ButtonBase
        visual="secondary"
        size="small"
        onClick={() => {
          if (onboarded && !approved) {
            showModal(UserAccessModal, {
              type: 'infoApprovalPending',
            });

            return;
          }

          showModal(UserAccessModal, {
            type: 'warningTransfer',
          });
        }}
      >
        {translatedValues.buttonTransferTopNav}
      </ButtonBase>
    );
  }

  return (
    <Drawer
      id="crypto-transfers-drawer"
      disableOverlayClose
      contentNode={contentNode}
      buttonTriggerNode={
        <ButtonBase visual="secondary" size="small">
          {translatedValues.buttonTransferTopNav}
        </ButtonBase>
      }
    />
  );
};

export { CryptoTransfersDrawer };
