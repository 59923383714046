import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

const TopActiveSectionTitle: FC<{ children: string }> = ({ children }) => {
  return (
    <h2
      className={css({
        fontSize: '16px',
        lineHeight: '20px',
        color: 'neutrals.text_primary',
        fontWeight: '700',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 'small',
        position: 'relative',
        _after: {
          position: 'absolute',
          content: '""',
          top: 0,
          insetInlineStart: 0,
          insetInlineEnd: 0,
          height: '5px',
          backgroundColor: 'neutrals.stroke_primary',
        },
      })}
    >
      {children}
    </h2>
  );
};

export { TopActiveSectionTitle };
