import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';

interface SavingsPlanInfoProps {
  className?: string;
  label: string;
}

const SavingsPlanInfo = ({ label, className }: SavingsPlanInfoProps) => (
  <li
    className={`${css({
      color: 'neutrals.text_secondary',
      display: 'flex',
      alignItems: 'center',
      gap: 'extra_small_2',
    })} ${className}`}
  >
    <Icon icon="info" size="16" />
    <p
      className={css({
        fontSize: 'body.medium',
        fontWeight: 'body.medium',
        lineHeight: 'body.medium',
        letterSpacing: 'body.medium',
      })}
    >
      {label}
    </p>
  </li>
);

export { SavingsPlanInfo };
