import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../../../common';

const walletAddressItemStyles = css({
  display: 'flex',
  alignItems: 'center',
  py: 'medium',
  gap: 'small',
  borderBottom: '1px solid',
  borderColor: 'neutrals.divider',
  cursor: 'pointer',
  '&:hover, &[data-selected="true"]': {
    backgroundColor: 'neutrals.fill_focused',
    marginInlineStart: '-24px',
    marginInlineEnd: '-24px',
    padding: '24px',
  },
});

const avatarStyles = css({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: 'neutrals.fill_quaternary',
  color: 'neutrals.text_secondary',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'caption.small',
  fontWeight: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
});

const detailsStyles = css({
  flexGrow: 1,
  display: 'flex',
  flexDir: 'column',
  gap: 'extra_small_3',
});

const walletNameStyles = css({
  fontSize: 'label.medium_medium',
  fontWeight: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
});

const tagStyles = css({
  backgroundColor: 'neutrals.fill_quinary',
  borderRadius: '12px',
  padding: '3px 10px',
  color: 'neutrals.text_primary',
  marginInlineStart: '10px',
  fontSize: 'caption.small',
  fontWeight: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
});

const walletAddressStyles = css({
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
});

export interface WalletListItemProps {
  id: string;
  name: string;
  address: string;
  tag: string;
  onClick: () => void;
  selected: boolean;
}

export const WalletListItem = ({
  id,
  name,
  address,
  tag,
  onClick,
  selected,
}: WalletListItemProps) => {
  return (
    <div
      role="button"
      aria-label={`wallet address ${address}`}
      className={walletAddressItemStyles}
      onClick={onClick}
      data-selected={selected}
    >
      <div className={avatarStyles}>{id}</div>

      <div className={detailsStyles}>
        <div className={walletNameStyles}>
          <span>{name}</span>
          <span className={tagStyles}>{tag}</span>
        </div>

        <span className={walletAddressStyles}>{address}</span>
      </div>
    </div>
  );
};

export const WalletListItemSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      alignItems: 'center',
      py: 'medium',
      gap: 'small',
      borderBottom: '1px solid',
      borderColor: 'neutrals.divider',
    })}
  >
    <SkeletonElement width="40px" height="40px" borderRadius="40px" />

    <div className={detailsStyles}>
      <div className={css({ display: 'flex', gap: '10px' })}>
        <SkeletonElement width="80px" height="20px" />

        <SkeletonElement width="45px" height="20px" borderRadius="12px" />
      </div>

      <SkeletonElement width="190px" height="17px" />
    </div>
  </div>
);
