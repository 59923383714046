import { FC, useMemo } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import {
  TransfersCommonStepsIds,
  TransfersDepositStepsIds,
  TransfersSendStepsIds,
} from '../../../types';
import { getNextPageForGoingBack } from './getNextPageForGoingBack';

export interface IActionBarProps {
  transfersTranslations: TCryptoTransferTranslations;
  state: TTransfersNextStepStateWithActions;
  hasMultipleNetworks: boolean;
}

const TransfersActionBar: FC<IActionBarProps> = ({
  transfersTranslations,
  state,
  hasMultipleNetworks,
}) => {
  const { pageId, backAction, closeAction } = state;

  const buttonStyles = css({
    py: 'small',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  });

  const actionBarTitle: string | null = useMemo(() => {
    const TransfersStepsTitleTranslations = {
      [TransfersCommonStepsIds.ChooseAsset]: transfersTranslations.selectAsset,
      [TransfersCommonStepsIds.ChooseNetwork]:
        transfersTranslations.selectNetwork,
      [TransfersCommonStepsIds.AssetAndNetworkSummary]:
        transfersTranslations.assetAndNetwork,
      [TransfersDepositStepsIds.DepositWallet]:
        transfersTranslations.walletAddress,
      [TransfersSendStepsIds.Warning]: '', // no title
      [TransfersDepositStepsIds.DepositWarning]: '', // no title
      [TransfersSendStepsIds.ChooseAmount]: transfersTranslations.chooseAmount,
      [TransfersSendStepsIds.ChooseRecipient]:
        transfersTranslations.chooseRecipient,
      [TransfersSendStepsIds.MemoMissingWarning]:
        transfersTranslations.cryptoWithdrawalMemoIsMissingTitle,
      [TransfersSendStepsIds.Confirmation]: transfersTranslations.summary,
      [TransfersSendStepsIds.TwoFactorAuth]: transfersTranslations.summary,
    };

    const translation = TransfersStepsTitleTranslations[pageId];

    return translation || null;
  }, [transfersTranslations, pageId]);

  const onBackAction = () => {
    if (backAction.type === 'page' && backAction.pageId) {
      const nextPage = getNextPageForGoingBack(
        backAction.pageId,
        hasMultipleNetworks,
      );

      if (nextPage) {
        state.changeToPageAndBindActions({
          pageId: backAction.pageId,
          newBackAction: {
            type: 'page',
            pageId: nextPage,
          },
        });
      } else {
        state.changeToPageAndBindActions({
          pageId: backAction.pageId,
          newBackAction: {
            type: 'none',
          },
        });
      }
    }

    return;
  };

  const onCloseAction = () => {
    if (closeAction.type === 'page') {
      state.changeToPageAndBindActions({
        pageId: closeAction.pageId,
      });
    }

    if (closeAction.type === 'close') {
      state.twoStepClose();
    }

    return;
  };

  const isDifferentUrlForBack =
    backAction.type === 'page' && backAction.pageId !== pageId;

  const showBackButton = backAction.type === 'page' && isDifferentUrlForBack;

  return (
    <div
      aria-label="transfers action bar"
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        px: 'medium',
        minHeight: '56px',
      })}
    >
      <div
        className={css({
          width: '50px',
        })}
      >
        {/* BACK */}
        {showBackButton && (
          <button
            className={buttonStyles}
            aria-label={`back to previous crypto transfer step button`}
            onClick={onBackAction}
            style={{
              marginInlineEnd: 'auto',
            }}
          >
            <Icon icon="arrow-left" size={'24'} color="primary" />
          </button>
        )}
      </div>

      {/* title */}
      <span
        aria-label="crypto transfer form step title"
        className={css({
          textAlign: 'center',
          color: 'neutrals.text_primary',
          fontSize: '13px',
          lineHeight: '18px',
          fontWeight: '500',
        })}
      >
        {actionBarTitle}
      </span>

      {/* CLOSE */}
      <div
        className={css({
          width: '50px',
        })}
      >
        {closeAction.type !== 'none' && (
          <button
            aria-label={`close crypto transfer button`}
            className={buttonStyles}
            disabled={false}
            onClick={onCloseAction}
            style={{
              marginInlineStart: 'auto',
            }}
          >
            <Icon icon="dismiss" size={'24'} color="primary" />
          </button>
        )}
      </div>
    </div>
  );
};

export { TransfersActionBar };
