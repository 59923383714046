import { useState } from 'react';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import { useAppNotification } from '../../../../notifications/NotificationContextProvider';
import { valueFromFiatToAsset } from '../../../../common';
import { datadogErrorHelper } from '../../../../common/utils/datadogErrorHelper';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TransfersSendStepsIds } from '../../../types';
import { createCryptoWithdrawalOffer } from '../actions/createCryptoWithdrawalOffer.action';
import { useWithdrawalAmountInput } from './useWithdrawalAmountInput';
import { getMemoType } from '../../../utils/getMemoType';

interface HookProps {
  cryptoTransferState: TTransfersNextStepStateWithActions;
}

export const useWithdrawalForm = ({ cryptoTransferState }: HookProps) => {
  const { setAppNotification } = useAppNotification();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const {
    selectedAssetData,
    selectedNetworkData,
    recipientAddress,
    recipientMemo,
    bindCreatedWithdrawalOfferData,
    changeToPageAndBindActions,
    setAssetAmountType,
  } = cryptoTransferState;

  const [isLoading, setIsLoading] = useState(false);

  const {
    amountInputValue,
    amountInputValueType,
    amountInputErrors,
    validateInputAndCheckForThresholdsErrors,
    changeAmountValueType,
  } = useWithdrawalAmountInput({ selectedAssetData, selectedNetworkData });

  const onSubmitCallCreateOfferMutation = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    const selectedAmount =
      amountInputValueType === 'FIAT'
        ? valueFromFiatToAsset(
            amountInputValue,
            selectedAssetData?.price,
            selectedNetworkData?.maximumTransactionPrecision,
          )?.value
        : amountInputValue;

    setIsLoading(true);

    try {
      const createdCryptoWithdrawalResponse = await createCryptoWithdrawalOffer(
        {
          assetID: selectedAssetData?.id as string,
          assetAmount: selectedAmount.replace(/,/g, '.'),
          coinNetworkId: selectedNetworkData?.coinNetworkId as string,
          toAddress: recipientAddress as string,
          ...(selectedNetworkData?.destinationTag && recipientMemo
            ? {
                destinationTag: recipientMemo,
                destinationTagType: getMemoType(
                  recipientMemo,
                  selectedAssetData?.symbol as string,
                ),
              }
            : {}),
        },
      );

      const errors = createdCryptoWithdrawalResponse?.errors;

      if (errors) {
        processErrors(errors);

        setIsLoading(false);

        return;
      }

      bindCreatedWithdrawalOfferData(
        createdCryptoWithdrawalResponse.data.createCryptoWithdrawal,
      );

      setAssetAmountType(amountInputValueType);

      changeToPageAndBindActions({
        pageId: TransfersSendStepsIds.Confirmation,
        newBackAction: {
          pageId: TransfersSendStepsIds.ChooseAmount,
          type: 'page',
        },
      });
    } catch (e) {
      setAppNotification({
        title: 'ERROR',
        subtitle:
          '[MISSING TRANSLATION] Failed to create crypto transfer withdrawal offer.',
        customDuration: 5000,
        withClose: true,
        visual: 'error',
        id: 'error-create-withdrawal-offer',
      });

      datadogErrorHelper({
        errorMessage:
          '[LBBW] Failed to create crypto transfer withdrawal offer.',
        errorSeverity: DatadogErrorLevels.CRITICAL,
      });
    }

    setIsLoading(false);
  };

  return {
    handlers: {
      changeAmountValueType,
      validateInputAndCheckForThresholdsErrors,
      onSubmit: onSubmitCallCreateOfferMutation,
    },
    formState: {
      isLoading,
      amountInputErrors,
      amountInputValue,
      amountInputValueType,
    },
  };
};
