'use client';

import { FC, useEffect } from 'react';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import { TransfersCommonStepsIds } from '../../../../types';

const RedirectToAssetSelection: FC<{
  state: TTransfersNextStepStateWithActions;
}> = ({ state }) => {
  useEffect(() => {
    state.changeToPageAndBindActions({
      pageId: TransfersCommonStepsIds.ChooseAsset,
      newBackAction: {
        pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
        type: 'page',
      },
    });
  }, [state]);

  return null;
};

export { RedirectToAssetSelection };
