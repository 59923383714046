'use client';

import { FC } from 'react';
import { css, divider } from '@bts-web/utils-style-engine';
import { AssetImage } from '../../../../common/components/AssetImage/AssetImage.use-client';
import { AssetImageComponentProps } from '../../../../common/types/components';
import { EntryRightIcon } from './EntryRightIcon';

export type ITransferPickerEntryType =
  | 'pickerParentWithValue'
  | 'entryWithActiveState';

interface BaseTransferEntityPickerEntryProps {
  imageUrl?: AssetImageComponentProps['src'] | null;
  title: string;
  subTitle?: string | null;
  heading?: string;
  type: ITransferPickerEntryType;
  onPick?: () => void;
  endInfoMain?: string;
  endInfoSub?: string;
}

interface WithListProps extends BaseTransferEntityPickerEntryProps {
  active: boolean;
  withinList: boolean;
}

interface WithoutListProps extends BaseTransferEntityPickerEntryProps {
  active?: never;
  withinList?: never;
}

type TransferEntityPickerEntryProps = WithListProps | WithoutListProps;

const TransferEntityPickerEntry: FC<TransferEntityPickerEntryProps> = ({
  title,
  type,
  imageUrl,
  onPick,
  heading,
  subTitle,
  endInfoMain,
  endInfoSub,
  active,
  withinList,
}) => {
  const ElementNode = withinList ? 'li' : 'div';

  return (
    <ElementNode
      role="option"
      aria-label={`${title}`}
      aria-selected={type === 'pickerParentWithValue'}
    >
      <button
        aria-label={`change option for ${heading}`}
        className={css({
          display: 'flex',
          alignItems: 'center',
          py: 'small',
          width: '100%',
          border: 'none',
          background: 'none',
          cursor: 'pointer',
        })}
        onClick={onPick}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 'extra_small_2',
          })}
        >
          {heading ? (
            <div
              className={css({
                color: 'neutrals.text_secondary',
                fontSize: '11px',
                lineHeight: '12px',
                textAlign: 'start',
              })}
            >
              {heading}
            </div>
          ) : null}

          <div
            className={css({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              gap: 'medium',
            })}
          >
            {imageUrl && (
              <AssetImage
                src={imageUrl}
                alt={`fallback for ${title}`}
                width={32}
                height={32}
              />
            )}
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
              })}
            >
              <h3
                className={css({
                  fontSize: '15px',
                  lineHeight: '20px',
                  fontWeight: 500,
                  margin: 0,
                  textAlign: 'start',
                })}
              >
                {title}
              </h3>

              {subTitle && (
                <div
                  className={css({
                    color: 'neutrals.text_secondary',
                    fontSize: '12px',
                    lineHeight: '17px',
                    textAlign: 'start',
                  })}
                >
                  {subTitle}
                </div>
              )}
            </div>

            {endInfoMain || endInfoSub ? (
              <div
                className={css({
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  marginInlineStart: 'auto',
                  gap: 'extra_small_3',
                })}
              >
                {endInfoMain ? (
                  <span
                    className={css({
                      color: 'neutrals.text_primary',
                      fontSize: 'label.medium_medium',
                      fontWeight: 'label.medium_medium',
                      lineHeight: 'label.medium_medium',
                      letterSpacing: 'label.medium_medium',
                    })}
                  >
                    {endInfoMain}
                  </span>
                ) : null}

                {endInfoSub ? (
                  <span
                    className={css({
                      color: 'neutrals.text_secondary',
                      fontSize: 'caption.small',
                      fontWeight: 'caption.small',
                      lineHeight: 'caption.small',
                      letterSpacing: 'caption.small',
                    })}
                  >
                    {endInfoSub}
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        {active && withinList ? (
          <EntryRightIcon icon="checkmark" />
        ) : !withinList ? (
          <EntryRightIcon icon="arrow-right" />
        ) : null}
      </button>

      <div
        className={divider({
          width: 'auto',
          color: 'neutrals.divider',
        })}
      />
    </ElementNode>
  );
};

export { TransferEntityPickerEntry };
