import { css } from '@bts-web/utils-style-engine';

export const dividerStyles = css({
  width: 'auto',
  borderBottomWidth: '1px',
  borderBottomColor: 'neutrals.divider',
  padding: 0,
});

export const Divider = ({ className }: { className?: string }) => (
  <div className={`${dividerStyles} ${className || ''}`} />
);
