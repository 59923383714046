import { PropsWithChildren } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { HiddenMobile, DesktopTopHeaderBarSkeleton } from '../../../../layouts';
import { DesktopLeftNavSkeleton } from '../../../../layouts/LayoutWithLeftDesktopNav/DesktopLeftNav/DesktopLeftNavSkeleton';

export const PageSkeletonWrapper = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={css({
        lg: {
          paddingTop: 'calc(var(--spacing-extra_small_4) + 72px)',
          paddingInlineStart: 'calc(var(--spacing-extra_small_4) + 73px)',
        },
        height: '100vh',
      })}
    >
      <HiddenMobile>
        <DesktopTopHeaderBarSkeleton />
      </HiddenMobile>

      <HiddenMobile>
        <DesktopLeftNavSkeleton />
      </HiddenMobile>

      <div
        className={css({
          width: {
            base: '100%',
            lg: '1440px',
          },
          maxWidth: '100%',
          mx: 'auto',
          height: {
            base: '100%',
            lg: 'calc(100vh - var(--spacing-extra_small_4) - 74px)',
          },
        })}
      >
        {children}
      </div>
    </div>
  );
};
