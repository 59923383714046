import { IResponsiveLayoutTranslations } from './types';

export const responsiveLayoutLanguageChangeTranslations: IResponsiveLayoutTranslations =
  {
    englishSubtitle: 'select_language_en',
    errorSubtitle: 'info_toast_asset_unavailable_subtitle',
    errorTitle: 'internal_error_headline',
    germanSubtitle: 'select_language_de',
    title: 'language',
  };

export const getResponsiveLayoutTranslations = (
  t: (translationKey: keyof IResponsiveLayoutTranslations) => string,
): IResponsiveLayoutTranslations => {
  return Object.entries(responsiveLayoutLanguageChangeTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof IResponsiveLayoutTranslations] = t(
        value as keyof IResponsiveLayoutTranslations,
      );

      return acc;
    },
    {} as IResponsiveLayoutTranslations,
  );
};
