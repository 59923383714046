import { GetPaginatedAssetsForTransfersQuery } from '@bts-web/data-layer/server';
import { TPaginatedAssetsForTransfersResponse } from './types';
import { TAssetForTransferArray } from '../../types';
import { PrecisionFloat } from '@bts-web/utils-formatting';
import { CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE } from '../../../config';

export const getPaginatedAssetsForTransferInitialQueryParams = {
  first: CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE,
  type: 'CRYPTOCOIN',
} satisfies GetPaginatedAssetsForTransfersQuery['variables']['input'];

export const EmptyPaginatedAssetsForTransfersResponse = {
  assets: {
    edges: [],
    totalCount: 0,
    pageInfo: {
      hasNextPage: false,
      endCursor: null,
      hasPreviousPage: false,
      startCursor: null,
    },
  },
} satisfies TPaginatedAssetsForTransfersResponse;

export const extractDepositAndSellAssets = (
  responseData: TPaginatedAssetsForTransfersResponse,
) => {
  const assetsListForDeposit: TAssetForTransferArray = [];

  const assetsListForSend: TAssetForTransferArray = [];

  responseData?.assets?.edges?.forEach((item) => {
    if (item?.node.networkInformation) {
      assetsListForDeposit.push(item.node);

      if (
        Number((item.node.portfolio?.assetBalance as PrecisionFloat)?.value) > 0
      ) {
        assetsListForSend.push(item.node);
      }
    }
  });

  return { assetsListForDeposit, assetsListForSend };
};
