import { CryptoAddressDestinationTagType } from '@bts-web/data-layer/server';

export const getMemoType = (
  memo: string,
  assetSymbol: string,
): CryptoAddressDestinationTagType => {
  // for Stellar coin type, the destinationTag might be alphanumeric
  // source: https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/destination-tag-memo-faq
  if (assetSymbol === 'XLM') {
    return /^\d+$/.test(memo) ? 'MEMO_ID' : 'MEMO_TEXT';
  }

  return 'MEMO_ID';
};
