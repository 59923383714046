import { css } from '@bts-web/utils-style-engine';
import { ProgressCircleIndeterminate as DesignProgressCircleIndeterminate } from '@bts-web/design-system/component/progress-circle';

const ProgressCircleIndeterminate = () => {
  return (
    <DesignProgressCircleIndeterminate
      aria-label="indeterminate loading circle"
      className={css({
        display: 'inline-block',
        overflow: 'hidden',
        width: '48px',
        height: '48px',
        '& [data-element="spinner-container"]': {
          position: 'relative',
          insetInlineStart: '50%',
          transform: 'translateX(-50%)',
          backfaceVisibility: 'hidden',
          transformOrigin: '0 0',
        },
        '& [data-element="spinner"]': {
          width: '48px',
          height: '48px',
          position: 'absolute',
          borderWidth: '4px',
          borderColor: 'neutrals.text_primary',
          borderTopColor: 'transparent',
          borderRadius: '50%',
          animation: 'spin 2s linear infinite',
        },
      })}
    />
  );
};

export { ProgressCircleIndeterminate };
