'use client';

import {
  useContext,
  createContext,
  useState,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { TAssetForTransferArray } from '../../types';
import { debounce } from '@bts-web/utils-functions';
import { MINIMUM_SEARCH_TERM_LENGTH } from '../../../config/prices';
import { getPaginatedAssetsForTransfer } from '../../../common/gqlActions';
import { TPaginatedAssetsForTransfersResponse } from '../TransfersLoader/types';
import {
  EmptyPaginatedAssetsForTransfersResponse,
  extractDepositAndSellAssets,
} from '../TransfersLoader/utils';
import { datadogErrorHelper } from '../../../common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DatadogErrorLevels } from '../../../../../../utils-next-infrastructure';
import { CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE } from '../../../config';

interface TransfersAssetListContextProps {
  assetsListForDeposit: TAssetForTransferArray;
  assetsListForSend: TAssetForTransferArray;
  isAssetsListLoading: boolean;
  onInputChange: (input: string) => void;
  assetFilterSearchValue: string;
  clearSearchParams: () => void;
  canLoadMore: boolean;
  loadMore: () => void;
}

const DEBOUNCE_DELAY_AMOUNT_MS = 500;

const TransfersAssetListContext =
  createContext<TransfersAssetListContextProps | null>(null);

export function useTransfersAssetsListContext() {
  const contextValue = useContext(TransfersAssetListContext);

  if (contextValue === null) {
    throw new Error(
      'useTransfersAssetsListContext must be used within a TransfersAssetListContextProvider',
    );
  }

  return contextValue;
}

interface TransfersAssetListContextProviderProps extends PropsWithChildren {
  initialData: NonNullable<TPaginatedAssetsForTransfersResponse>;
}

export const TransfersAssetListContextProvider = ({
  initialData,
  children,
}: TransfersAssetListContextProviderProps) => {
  const [fullAssetData, setFullAssetData] =
    useState<TPaginatedAssetsForTransfersResponse>(initialData);

  const [assetFilterSearchValue, setAssetFilterSearchValue] = useState('');

  const [loading, setLoading] = useState(false);

  const { assetsListForDeposit, assetsListForSend, canLoadMore, endCursorId } =
    useMemo(() => {
      const processed = extractDepositAndSellAssets(fullAssetData);

      return {
        canLoadMore: fullAssetData?.assets?.pageInfo?.hasNextPage ?? false,
        endCursorId: fullAssetData?.assets?.pageInfo?.endCursor ?? null,
        assetsListForDeposit: processed.assetsListForDeposit,
        assetsListForSend: processed.assetsListForSend,
      };
    }, [fullAssetData]);

  const debouncedSearchRef = useRef(
    debounce(async (input: string) => {
      const sanitizedInput = input.trim();

      if (
        sanitizedInput.length === 0 ||
        sanitizedInput.length < MINIMUM_SEARCH_TERM_LENGTH
      ) {
        setFullAssetData(initialData);

        setLoading(false);

        return;
      }

      try {
        const { data } = await getPaginatedAssetsForTransfer({
          searchTerm: sanitizedInput,
          first: CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE,
        });

        setFullAssetData(
          data ? data : EmptyPaginatedAssetsForTransfersResponse,
        );
      } catch (error) {
        console.error('Error fetching assets:', error);
      } finally {
        setLoading(false);
      }
    }, DEBOUNCE_DELAY_AMOUNT_MS),
  );

  const loadMore = useCallback(async () => {
    if (!canLoadMore || !endCursorId) {
      return;
    } else {
      try {
        const { data: responseData } = await getPaginatedAssetsForTransfer({
          first: CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE,
          after: endCursorId,
        });

        setFullAssetData((prevData) => {
          if (!responseData) {
            return prevData;
          }

          return {
            assets: {
              edges: [
                ...(prevData?.assets?.edges ?? []),
                ...(responseData?.assets?.edges ?? []),
              ],
              totalCount: responseData?.assets?.totalCount ?? 0,
              pageInfo:
                responseData?.assets?.pageInfo ??
                EmptyPaginatedAssetsForTransfersResponse.assets.pageInfo,
            },
          };
        });
      } catch (error) {
        datadogErrorHelper({
          errorMessage: 'Error fetching more assets' + JSON.stringify(error),
          errorSeverity: DatadogErrorLevels.HIGH,
        });
      }
    }
  }, [canLoadMore, endCursorId]);

  const onInputChange = useCallback((newValue: string) => {
    setAssetFilterSearchValue(newValue);

    setLoading(true);

    debouncedSearchRef.current(newValue);
  }, []);

  const clearSearchParams = useCallback(() => {
    setAssetFilterSearchValue('');

    setLoading(false);

    debouncedSearchRef.current('');
  }, []);

  const contextValue = useMemo(
    () => ({
      onInputChange,
      assetsListForDeposit,
      assetsListForSend,
      isAssetsListLoading: loading,
      assetFilterSearchValue,
      clearSearchParams,
      canLoadMore,
      loadMore,
    }),
    [
      onInputChange,
      assetsListForDeposit,
      assetsListForSend,
      loading,
      assetFilterSearchValue,
      clearSearchParams,
      canLoadMore,
      loadMore,
    ],
  );

  return (
    <TransfersAssetListContext.Provider value={contextValue}>
      {children}
    </TransfersAssetListContext.Provider>
  );
};
