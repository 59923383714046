'use client';

import { FC } from 'react';
import { MobileLanguageDrawer } from '../../LayoutWithLeftDesktopNav/MobileLanguageDrawer/MobileLanguageDrawer';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { getResponsiveLayoutTranslations } from '../utils';

const ResponsiveLayoutWithLogoLanguageSwitcher: FC<{
  contrastVariant?: boolean;
}> = ({ contrastVariant }) => {
  const { t } = useClientTranslation();

  const translations = getResponsiveLayoutTranslations(t as TFunction);

  return (
    <MobileLanguageDrawer
      translations={translations}
      contrastVariant={contrastVariant}
    />
  );
};

export { ResponsiveLayoutWithLogoLanguageSwitcher };
