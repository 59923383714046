import Image from 'next/image';
import { css } from '@bts-web/utils-style-engine';
import { IntroHeader, IntroHeaderProps } from '../IntroHeader/IntroHeader';
import BitpandaBWhite from './BitpandaBWhite.svg';
import { ResponsiveLayoutWithLogo } from '../../../layouts/ResponsiveLayoutWithLogo/ResponsiveLayoutWithLogo';
import { HiddenDesktop } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';
import { ResponsiveLayoutWithLogoLanguageSwitcher } from '../../../layouts/ResponsiveLayoutWithLogo/subcomponents/ResponsiveLayoutWithLogoLanguageSwitcher';
import { FC } from 'react';

export interface InfoLayoutProps
  extends IntroHeaderProps,
    React.PropsWithChildren {}

const infoLayoutContainerStyles = css({
  backgroundColor: 'brand.fill_secondary',
  borderRadius: {
    base: 0,
    lg: 8,
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  px: 'medium',
  paddingTop: 'extra_large',
});

const InfoLayoutMobileHeader: FC<{ showLanguageSwitcher?: boolean }> = ({
  showLanguageSwitcher,
}) => {
  if (showLanguageSwitcher) {
    return (
      <HiddenDesktop>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <Image
            width={23}
            height={34}
            priority
            src={BitpandaBWhite}
            alt="Bitpanda"
          />
          <ResponsiveLayoutWithLogoLanguageSwitcher contrastVariant />
        </div>
      </HiddenDesktop>
    );
  }

  return (
    <HiddenDesktop>
      <Image
        width={23}
        height={34}
        priority
        src={BitpandaBWhite}
        alt="Bitpanda"
      />
    </HiddenDesktop>
  );
};

export const InfoLayout = ({
  children,
  showLanguageSwitcher,
  ...introHeaderProps
}: InfoLayoutProps) => (
  <ResponsiveLayoutWithLogo showLanguageSwitcher={showLanguageSwitcher}>
    <div className={infoLayoutContainerStyles}>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'medium',
        })}
      >
        <InfoLayoutMobileHeader showLanguageSwitcher={showLanguageSwitcher} />
        <IntroHeader {...introHeaderProps} />
      </div>
      {children}
    </div>
  </ResponsiveLayoutWithLogo>
);
