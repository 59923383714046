import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TransferWarningHeaderBox } from '../../Reusable/TransferWarningHeaderBox/TransferWarningHeaderBox';
import { ButtonBase } from '../../../../common/components/ButtonBase/ButtonBase';
import { TransfersSendStepsIds } from '../../../types';

const labelLargeTypography = css({
  fontSize: 'label.large',
  fontWeight: 'label.large',
  lineHeight: 'label.large',
  letterSpacing: 'label.large',
});

interface TransferWarningScreenProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const MemoIsMissingWarningScreen: FC<TransferWarningScreenProps> = ({
  state,
  translations,
}) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        flex: 1,
      })}
    >
      <TransferWarningHeaderBox
        title={translations.cryptoWithdrawalMemoIsMissingTitle}
      />

      <p className={labelLargeTypography}>
        {translations.cryptoWithdrawalMemoMissingText}
      </p>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'medium',
          marginTop: 'auto',
        })}
      >
        <ButtonBase
          onClick={() => {
            state.changeToPageAndBindActions({
              pageId: TransfersSendStepsIds.ChooseAmount,
              newBackAction: {
                pageId: TransfersSendStepsIds.ChooseRecipient,
                type: 'page',
              },
            });
          }}
        >
          {translations.memoMissingButton}
        </ButtonBase>

        <ButtonBase
          visual="ghost"
          onClick={() => {
            state.twoStepClose();
          }}
        >
          {translations.cryptoWithdrawalCtaCancel}
        </ButtonBase>
      </div>
    </div>
  );
};

export { MemoIsMissingWarningScreen };
