import Image from 'next/image';
import { css } from '@bts-web/utils-style-engine';
import { convertToLocaleDateString } from '@bts-web/utils-formatting';
import moneyBoxIcon from './money-box-icon.svg';
import { DatePicker } from '../../../../../common';
import {
  getYmdForNthDayFromToday,
  getYmdForCurrentMonthLengthAndNthDays,
} from '../../../utils/dateUtils';
import { SavingsPlanDateConfigItem } from './components/SavingsPlanDateConfigItem';
import { Locale } from '@bts-web/utils-lokalise';

export const SetUpSavingsPlanBox = ({
  translations,
  startsAtValue,
  locale,
  onChangeDate,
}: {
  translations: {
    setUpSavingsPlanBoxTitle: string;
    setUpSavingsPlanBoxDescription: string;
    frequency: string;
    startsAt: string;
    monthly: string;
    ok: string;
    cancel: string;
  };
  startsAtValue: string;
  locale: Locale;
  onChangeDate: (date: string) => void;
}) => {
  return (
    <div
      data-tetid="set-up-savings-plan-box"
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'large',
        boxShadow:
          '0px 4px 15px 0px rgba(0, 0, 0, 0.10), 0px 10px 20px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        px: 'medium',
        py: 'small',
      })}
    >
      <div className={css({ display: 'flex', gap: 'small' })}>
        <div
          className={css({
            padding: 'extra_small',
            border: '1px solid',
            borderColor: 'positive.stroke_primary',
            borderRadius: '4px',
            minWidth: '46px',
            height: '46px',
          })}
        >
          <Image src={moneyBoxIcon} alt="money-box" width={20} height={20} />
        </div>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small_3',
          })}
        >
          <span
            className={css({
              fontSize: 'title.medium_medium',
              fontWeight: 'title.medium_medium',
              letterSpacing: 'title.medium_medium',
              lineHeight: 'title.medium_medium',
              color: 'neutrals.text_primary',
            })}
          >
            {translations.setUpSavingsPlanBoxTitle}
          </span>

          <span
            className={css({
              fontSize: 'caption.small_light',
              fontWeight: 'caption.small_light',
              letterSpacing: 'caption.small_light',
              lineHeight: 'caption.small_light',
              color: 'neutrals.text_secondary',
            })}
          >
            {translations.setUpSavingsPlanBoxDescription}
          </span>
        </div>
      </div>

      <ul>
        <li
          className={css({
            borderBottom: '1px solid',
            borderBottomColor: 'neutrals.divider',
          })}
        >
          <SavingsPlanDateConfigItem
            label={translations.frequency}
            value={translations.monthly}
          />
        </li>

        <li
          className={css({
            '& [data-element="date-picker-open-trigger"]': {
              width: '100%',
            },
          })}
        >
          <DatePicker
            value={startsAtValue}
            onChange={onChangeDate}
            cancelLabel={translations.cancel}
            confirmLabel={translations.ok}
            disabled={{
              before: new Date(getYmdForNthDayFromToday(4)),
              after: new Date(getYmdForCurrentMonthLengthAndNthDays(3)),
            }}
          >
            <SavingsPlanDateConfigItem
              label={translations.startsAt}
              value={convertToLocaleDateString(startsAtValue, locale)}
              hasIndicator
            />
          </DatePicker>
        </li>
      </ul>
    </div>
  );
};
