import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { Icon } from '@bts-web/design-system/component/icon';

const labelLargeTypography = css({
  fontSize: 'label.large',
  fontWeight: 'label.large',
  lineHeight: 'label.large',
  letterSpacing: 'label.large',
});

const iconStyles = css({
  color: 'warning.text_primary',
});

const warningItemStyles = css({
  display: 'flex',
  gap: 'medium',
  fontSize: 'label.large_bold',
  fontWeight: 'label.large_bold',
  lineHeight: 'label.large_bold',
  letterSpacing: 'label.large_bold',
  pt: 'extra_small',
  '&:last-of-type': {
    pb: 'extra_small',
  },
});

interface TransferWarningInformationProps {
  translations: TCryptoTransferTranslations;
}

const TransferWarningInformation: FC<TransferWarningInformationProps> = ({
  translations,
}) => {
  const warningItems = [
    {
      icon: 'wallet',
      text: translations.cryptoWithdrawalAddressWarning,
    },
    {
      icon: 'virtual-network',
      text: translations.cryptoWithdrawaNetworkWarning,
    },
    {
      icon: 'tag',
      text: translations.cryptoWithdrawalMemoWarning,
    },
  ];

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_2',
        flex: 1,
      })}
    >
      <p className={labelLargeTypography}>
        {translations.cryptoWithdrawalSubtitle}
      </p>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        {warningItems.map((item) => (
          <div className={warningItemStyles} key={item.icon}>
            <Icon
              icon={item.icon}
              size="32"
              theme="regular"
              data-element={`icon-${item.icon}`}
              className={iconStyles}
            />
            {item.text}
          </div>
        ))}
      </div>

      <p className={labelLargeTypography}>
        {translations.cryptoWithdrawalLossWarning}
      </p>
    </div>
  );
};

export { TransferWarningInformation };
