'use client';

import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { FC, useCallback, useMemo, useState, ReactNode } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { Icon } from '@bts-web/design-system/component/icon';
import { Locale } from '@bts-web/utils-lokalise';
import { AmountFor, TransactionStatus } from '@bts-web/data-layer/server';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import {
  PrecisionFloat,
  getPrecisionFloatValue,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';
import { isBpfsAsset } from '../../utils/isBpfsAsset';
import {
  AssetType,
  submitButtonWrapperAndButtonProperStyles,
  TradeControllerVariants,
} from '../../utils';
import { OfferLoaderElement } from '../../Reusable/OfferLoaderElement/OfferLoaderElement';
import { PoweredByText } from '../../Reusable/PoweredByText/PoweredByText';
import { TradeTaxSummary } from './components/TradeTaxSummary/TradeTaxSummary';
import { AcceptBuyTradeDisclaimerWrapper } from './components/AcceptBuyTradeDisclaimerWrapper/AcceptBuyTradeDisclaimerWrapper';
import { finishTradeRedirectBuilder } from '../../FinishTrade/actions/finishTradeRedirectBuilder.action';
import { TradeAssetInfoDisplay } from '../../../common/components/TradeAssetInfoDisplay/TradeAssetInfoDisplay';
import { TradeScreenTranslations } from '../../utils/getTradeTranslations';
import {
  AcceptTradeControllerVariants,
  TradeOfferDataNonNullable,
} from '../types';
import { AmountInputWrapper, ButtonBase, Divider } from '../../../common';
import { acceptTradeOffer, fetchOfferData } from '../../../common/gqlActions';
import { BelowInputInfoTable } from '../../../common/components/BelowInputInfoTable/BelowInputInfoTable';
import { getConvertedSummary } from '../../TradeParent/utils/getConvertedSummary';
import { removeInputValueFromLocalStorage } from '../../TradeParent/utils/localStorageInputValueUtils';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';
import { getMaximumTradeAmount } from '../../TradeParent/utils';
import { datadogErrorHelper } from '../../../common';
import { FeesList } from './components/FeesList/FeesList';

export type AcceptTradeSubscriptionWrapperProps = {
  initialOfferData: TradeOfferDataNonNullable;
  offerId: string;
  translations: TradeScreenTranslations;
  children?: ReactNode;
  variant: AcceptTradeControllerVariants;
  currentLocale: Locale;
  currency: string;
};

// THIS FUNCTION is required to be executed outside of a try-catch block, because a redirect would trigger a fail in the catch block
const executeRedirect = (
  variant: TradeControllerVariants,
  offerId: string,
  transactionStatus: TransactionStatus | null,
  hasTaxOfflineWarning?: boolean,
) => {
  finishTradeRedirectBuilder(
    variant,
    offerId,
    transactionStatus,
    hasTaxOfflineWarning,
  );
};

export const AcceptTradeSubscriptionWrapper: FC<
  AcceptTradeSubscriptionWrapperProps
> = ({
  initialOfferData,
  offerId,
  translations,
  children,
  variant,
  currentLocale,
  currency,
}) => {
  const { setAppNotification } = useAppNotification();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const router = useRouter();

  const [tradeOfferData, setTradeOfferData] =
    useState<TradeOfferDataNonNullable>(initialOfferData);

  const [nextExpiresAt, setNextExpiresAt] = useState(
    initialOfferData.expiresAt,
  );

  const [isTradeOfferViewQueryRefetching, setIsTradeOfferViewQueryRefetching] =
    useState(false);

  const [isOfferAccepted, setOfferAccepted] = useState(false);

  const offerAvailabilityInMs = useMemo(() => {
    const MINIMUM_REFRESH_TIME = 5000;

    if (!nextExpiresAt) return MINIMUM_REFRESH_TIME;

    const nextExpiresAtInMs = new Date(nextExpiresAt).getTime();

    const currentTimeInMs = Date.now();

    const newValue = nextExpiresAtInMs - currentTimeInMs;

    return Math.max(newValue, MINIMUM_REFRESH_TIME);
  }, [nextExpiresAt]);

  const refetchTradeOfferViewQuery = useCallback(async () => {
    if (isTradeOfferViewQueryRefetching || isOfferAccepted) return;

    setIsTradeOfferViewQueryRefetching(true);

    try {
      const response = await fetchOfferData(offerId);

      const tradeOffer = response.data.tradeOffer;

      if (!tradeOffer) {
        console.warn('%cNo trade offer data found', 'color:red');

        if (!isDevelopmentEnv()) {
          router.push('/error/server');
        }
      } else {
        setTradeOfferData(tradeOffer);

        setNextExpiresAt(tradeOffer.expiresAt);

        setAppNotification({
          title: translations.offerExpiredTitle,
          subtitle: translations.offerExpiredSubtitle,
          withClose: true,
          visual: 'info',
          customDuration: 2000,
        });

        document.dispatchEvent(new Event('onTradeOfferRefetched'));
      }
    } catch (e) {
      console.error('Error fetching trade offer:', e);

      if (!isDevelopmentEnv()) {
        router.push('/error/server');
      }
    } finally {
      setIsTradeOfferViewQueryRefetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offerId,
    isOfferAccepted,
    isDevelopmentEnv,
    router,
    setAppNotification,
    translations,
  ]);

  const handleSpinnerComplete = useCallback(() => {
    refetchTradeOfferViewQuery();
  }, [refetchTradeOfferViewQuery]);

  const { asset, totalAmount, taxAmount, fiatAmount, hasTaxOfflineWarning } =
    tradeOfferData;

  const {
    logoUrl,
    name,
    priceBuy,
    priceSell,
    symbol: initialSymbol,
    __typename,
  } = asset;

  const price = variant === 'buy' ? priceBuy : priceSell;

  const symbol =
    __typename === 'MetalAsset' ? translations.grams : initialSymbol;

  const defaultDisplayType: AmountFor = 'FIAT';

  const totalSum = getPrecisionFloatValue(totalAmount);

  const isBpf = isBpfsAsset(__typename as AssetType);

  const poweredByText = {
    value: isBpf ? translations.poweredByBpfs : translations.poweredByBitpanda,
    fontSize: isBpf ? 'caption.medium_medium' : 'caption.small',
  };

  const shouldShowTaxSummary =
    taxAmount && Number(taxAmount?.value) > 0 && fiatAmount?.value;

  const triggerAcceptTradeOffer = async () => {
    setIsTradeOfferViewQueryRefetching(true);

    setOfferAccepted(true);

    try {
      const offerResponse = await acceptTradeOffer(offerId, variant);

      const statusResponse = offerResponse.data.acceptTradeOffer?.status;

      if (offerResponse.errors) {
        processErrors(offerResponse.errors);
      } else if (!statusResponse) {
        if (!statusResponse) {
          throw new Error('No status response from accept trade offer');
        }
      }

      removeInputValueFromLocalStorage();

      executeRedirect(
        variant,
        offerId,
        statusResponse as TransactionStatus,
        !!hasTaxOfflineWarning,
      );
    } catch (error) {
      console.error('Error accepting trade offer:', error);

      setIsTradeOfferViewQueryRefetching(false);

      setOfferAccepted(false);

      if (typeof error === 'string') {
        processErrors([error] as string[]);
      } else {
        processErrors(error as unknown as string[]);
      }

      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.CRITICAL,
      });
    }
    // Do not remove the loading state as after the operation is completed, the duration until the redirect is not known, and the user could submit multiple times
    // finally {
    //   setIsTradeOfferViewQueryRefetching(false);

    //   setOfferAccepted(false);
    // }
  };

  const maximumTradeAmount = getMaximumTradeAmount({
    fiatBalance: asset.portfolio?.fiatBalance,
    assetBalance: asset.portfolio?.assetBalance,
    type: variant,
  });

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        px: 'medium',
        height: '100%',
      })}
    >
      <TradeAssetInfoDisplay
        assetData={{ logoUrl, name }}
        currentLocale={currentLocale}
        price={price}
        symbol={symbol}
        loaderElement={
          <OfferLoaderElement
            key={nextExpiresAt}
            duration={offerAvailabilityInMs}
            size={20}
            onComplete={handleSpinnerComplete}
          />
        }
      />
      {children}

      <AmountInputWrapper
        inputContextData={{
          symbol: asset.symbol ?? '',
          currency,
          currentLocale: currentLocale,
          displayType: defaultDisplayType,
          value: totalSum,
          placeholder: undefined,
        }}
      />

      <div
        className={css({
          pt: 'extra_small',
          pb: 'medium',
        })}
      >
        <BelowInputInfoTable
          items={[
            {
              title: translations['units'],
              value: getConvertedSummary({
                assetPrice: price,
                currency: currency,
                displayType: defaultDisplayType,
                locale: currentLocale,
                symbol: symbol,
                translations: { inclSpread: translations.inclSpread },
                value: totalSum,
                useRawSource: {
                  rawSourceDisplayType: 'ASSET',
                  rawSourceValue: tradeOfferData.assetAmount as PrecisionFloat,
                },
                maximumTradeAmount,
                showInclSpread: true,
              }),
            },
          ]}
        />
      </div>

      <Divider />

      <FeesList
        variant={variant}
        translations={translations}
        values={{
          paymentMethod: translations.sepaDirectDebit,
          amount: tradeOfferData.assetAmount
            ? `${Number(tradeOfferData.assetAmount.value).toFixed(
                tradeOfferData.assetAmount.precision,
              )} ${tradeOfferData.asset.symbol}`
            : '',
          offerPrice: price
            ? intlAssetValueFormatting(price.value, {
                locale: currentLocale,
                notation: 'standard',
                currency: 'EUR',
                fractionDigits: price?.precision,
              })
            : '',
          tradeFee: tradeOfferData.spread
            ? intlAssetValueFormatting(tradeOfferData.spread.value, {
                locale: currentLocale,
                notation: 'standard',
                currency: 'EUR',
                fractionDigits: price?.precision,
              })
            : '0',
        }}
      />

      {shouldShowTaxSummary && (
        <TradeTaxSummary
          currency={currency}
          currentLocale={currentLocale}
          fiatAmount={fiatAmount}
          isTaxServiceOffline={!!hasTaxOfflineWarning}
          taxAmount={taxAmount}
          translations={translations}
        />
      )}

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 'medium',
          gap: 'extra_small',
          marginTop: 'auto',
        })}
      >
        <AcceptBuyTradeDisclaimerWrapper
          translations={translations}
          variant={variant}
          legalUrl={asset.legalUrl ?? ''}
          assetType={asset.__typename as AssetType}
        >
          {({ areTermsAgreedTo, isDisclaimerLoading }) => {
            const isLoading =
              isDisclaimerLoading || isTradeOfferViewQueryRefetching;

            const isDisabled = !areTermsAgreedTo || isLoading;

            return (
              <>
                <PoweredByText {...poweredByText} />

                <div className={submitButtonWrapperAndButtonProperStyles}>
                  <ButtonBase
                    visual="accent"
                    size="large"
                    disabled={isDisabled}
                    onClick={triggerAcceptTradeOffer}
                    fullWidth
                  >
                    {variant === 'buy'
                      ? translations.buyNow
                      : translations.sellNow}

                    {isLoading && (
                      <Icon
                        data-element="loading-icon"
                        theme="filled"
                        size="20"
                        icon="spinner-ios"
                        aria-label="loading icon"
                      />
                    )}
                  </ButtonBase>
                </div>
              </>
            );
          }}
        </AcceptBuyTradeDisclaimerWrapper>
      </div>
    </div>
  );
};
