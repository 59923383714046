/*
 * CREATE OFFER - WITHDRAWAL
 */

import { css } from '@bts-web/utils-style-engine';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { AmountFor } from '@bts-web/data-layer/server';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { getPrecisionFloatValue } from '@bts-web/utils-formatting';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import {
  AlertBox,
  ButtonBase,
  AmountInput,
  AmountInputWrapper,
  BelowInputInfoTable,
  InputError,
  getAmountInputPlaceHolder,
  DisplaySwitchWrapper,
  TradeAssetInfoDisplay,
  valueFromAssetToFiat,
  // AmountQuickSelectButton
} from '../../../../common';
// import { DividerElement } from '../../.././../trade/TradeParent/MainTradeForm/subcomponents/DividerElement';
import { getConvertedSummary } from '../../../../trade/TradeParent/utils/getConvertedSummary';
import { useWithdrawalForm } from './useWithdrawalForm';

interface ChooseAmountScreenProps {
  cryptoTransferState: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
  currentLocale: typeof defaultLocale;
}

export const ChooseAmountScreen = ({
  cryptoTransferState,
  translations,
  currentLocale,
}: ChooseAmountScreenProps) => {
  const { selectedAssetData, selectedNetworkData } = cryptoTransferState;

  const {
    formState: {
      amountInputValue,
      amountInputValueType,
      amountInputErrors,
      isLoading,
    },
    handlers: {
      onSubmit,
      changeAmountValueType,
      validateInputAndCheckForThresholdsErrors,
    },
  } = useWithdrawalForm({ cryptoTransferState });

  const { t } = useClientTranslation();

  return (
    <>
      <TradeAssetInfoDisplay
        currentLocale={currentLocale}
        assetData={{
          name: selectedAssetData?.name as string,
          logoUrl: selectedAssetData?.logoUrl as string,
        }}
        symbol={selectedAssetData?.symbol as string}
        price={selectedAssetData?.price}
        settings={{
          hideTopBorder: true,
        }}
      />

      <form
        onSubmit={onSubmit}
        className={css({
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        })}
      >
        <AmountInputWrapper
          inputContextData={{
            displayType: amountInputValueType,
            value: amountInputValue,
            currency: 'EUR',
            symbol: selectedAssetData?.symbol as string,
            currentLocale: currentLocale,
            placeholder: getAmountInputPlaceHolder({
              locale: currentLocale,
              currency: 'EUR',
              symbol: selectedAssetData?.symbol as string,
              displayType: amountInputValueType,
            }),
          }}
          switcherElement={
            <DisplaySwitchWrapper
              parentValue={amountInputValueType}
              items={[
                {
                  label: translations.fiat,
                  value: 'FIAT' as AmountFor,
                },
                {
                  label: translations.units,
                  value: 'ASSET' as AmountFor,
                },
              ]}
              onChange={(value) => changeAmountValueType(value)}
            />
          }
        >
          {({ inputRef, modifiedBlur }) => {
            return (
              <AmountInput
                ref={inputRef}
                currentLocale={currentLocale}
                placeholder={getAmountInputPlaceHolder({
                  locale: currentLocale,
                  currency: 'EUR',
                  symbol: selectedAssetData?.symbol as string,
                  displayType: amountInputValueType,
                })}
                value={amountInputValue}
                onChange={(e) =>
                  validateInputAndCheckForThresholdsErrors(
                    e.target.value,
                    amountInputValueType,
                  )
                }
                onBlur={modifiedBlur}
              />
            );
          }}
        </AmountInputWrapper>

        <div
          data-testid="choose-amount-screen-below-input"
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'small',
          })}
        >
          <BelowInputInfoTable
            items={[
              {
                title: translations.units,
                value: getConvertedSummary({
                  assetPrice: selectedAssetData?.price,
                  currency: 'EUR',
                  displayType: amountInputValueType,
                  locale: currentLocale,
                  symbol: selectedAssetData?.symbol as string,
                  value: amountInputValue,
                }),
              },
            ]}
          />

          {amountInputErrors.minWithdrawalAmountNotReached && (
            <InputError
              message={`${t('crypto_withdrawal_minimum_amount_error_subtitle', {
                coin: selectedAssetData?.name,
                amount:
                  amountInputValueType === 'FIAT'
                    ? `${
                        valueFromAssetToFiat(
                          selectedNetworkData?.minWithdrawalThreshold
                            ?.value as string,
                          selectedAssetData?.price,
                        )?.value
                      } EUR`
                    : `${getPrecisionFloatValue(selectedNetworkData?.minWithdrawalThreshold)} ${selectedAssetData?.symbol}`,
              })}`}
            />
          )}

          {amountInputErrors.portfolioAmountExceeded && (
            <InputError
              message={`${t(
                'crypto_withdrawal_portfolio_exceeded_error_subtitle',
                {
                  amount:
                    amountInputValueType === 'FIAT'
                      ? `${getPrecisionFloatValue(
                          selectedAssetData?.portfolio.fiatBalance,
                        )} EUR`
                      : `${getPrecisionFloatValue(selectedAssetData?.portfolio.assetBalance)} ${selectedAssetData?.symbol}`,
                  coin: selectedAssetData?.name,
                },
              )}`}
            />
          )}

          {/* TODO: re-enable below commented code and integrate it when networkFee is available on NetworkAddress GQL type
              See: https://bitpanda.atlassian.net/browse/BU-1850

          <DividerElement />

          <AmountQuickSelectButton
            active={false}
            key={500}
            data={{
              label: 'Max. (500, 00 EUR)',
              value: '500',
              fiatAndAssetValues: {
                FIAT: {
                  value: '',
                  precision: 2,
                },
                ASSET: {
                  value: '',
                  precision: 2,
                },
              },
              isMax: true,
            }}
            onClick={() => console.log('quick selection')}
            disabled={false}
          /> */}
        </div>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 'small',
            marginTop: 'auto',
          })}
        >
          <AlertBox variant="info">
            {translations.inlineInfoCryptoTransferNewWalletTitle}
          </AlertBox>

          <ButtonBase
            type="submit"
            isLoading={isLoading}
            disabled={
              !amountInputValue ||
              isLoading ||
              amountInputErrors.minWithdrawalAmountNotReached ||
              amountInputErrors.portfolioAmountExceeded
            }
          >
            {translations.next}
          </ButtonBase>
        </div>
      </form>
    </>
  );
};
