import { css } from '@bts-web/utils-style-engine';
import { WalletListItem, WalletListItemSkeleton } from './WalletsListItem';
import { WalletListItemProps } from './WalletsListItem';

export const WalletsList = ({
  translations,
  items,
  selectedItemId,
}: {
  translations: { savedWallets: string };
  items: Array<Omit<WalletListItemProps, 'onClick' | 'selected'>>;
  onChooseItem: (walletAddress: string) => void;
  selectedItemId: string;
}) => {
  const chooseWallet = (address: string) => console.log(address);

  return (
    <div
      className={css({
        paddingTop: 'large',
        borderTop: '1px solid',
        borderColor: 'neutrals.divider',
      })}
    >
      <span
        className={css({
          fontSize: 'caption.small_light',
          fontWeight: 'caption.small_light',
          lineHeight: 'caption.small_light',
          letterSpacing: 'caption.small_light',
          color: 'neutrals.text_secondary',
        })}
      >
        {translations.savedWallets}
      </span>

      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <WalletListItem
              id={item.id}
              name={item.name}
              address={item.address}
              tag={item.tag}
              onClick={() => chooseWallet(item.address)}
              selected={selectedItemId === item.id}
            />
          </li>
        ))}
      </ul>

      {/* TODO: use below skeleton when loading due to search */}
      {/* <WalletsListSkeleton /> */}
    </div>
  );
};

export const WalletsListSkeleton = () => (
  <ul>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
  </ul>
);
