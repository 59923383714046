import { useState, useCallback } from 'react';
import { AmountFor } from '@bts-web/data-layer/server';
import { valueFromAssetToFiat, valueFromFiatToAsset } from '../../../../common';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { validateAmountInput } from '../../../../common/utils/amountInputs/validateAmountInput';

interface HookProps {
  selectedAssetData: TTransfersNextStepStateWithActions['selectedAssetData'];
  selectedNetworkData: TTransfersNextStepStateWithActions['selectedNetworkData'];
}

export const useWithdrawalAmountInput = ({
  selectedAssetData,
  selectedNetworkData,
}: HookProps) => {
  const [amountInputValueType, setInputValueType] = useState(
    'FIAT' as AmountFor,
  );

  const [amountInputValue, setAmountInputValue] = useState('');

  const [amountInputErrors, setAmountInputErrors] = useState({
    minWithdrawalAmountNotReached: false,
    portfolioAmountExceeded: false,
  });

  const validateInputAndCheckForThresholdsErrors = (
    newValue: string,
    type: AmountFor,
  ) => {
    const isIntroducedValueAccepted = validateAmountInput(
      newValue,
      amountInputValueType,
      selectedNetworkData?.maximumTransactionPrecision,
    );

    if (!isIntroducedValueAccepted) return;

    setAmountInputValue(newValue);

    const parsedNewValue = newValue.replace(',', '.'); // user can introduce either ',' or '.' for expressing decimals

    const amountOfUnits =
      type === 'FIAT'
        ? valueFromFiatToAsset(
            parsedNewValue,
            selectedAssetData?.price,
            selectedNetworkData?.maximumTransactionPrecision,
          )?.value
        : parsedNewValue;

    const isMinWithdrawalReached = selectedNetworkData?.minWithdrawalThreshold
      ? Number(amountOfUnits) >=
        Number(selectedNetworkData?.minWithdrawalThreshold.value)
      : true;

    const isPortfolioAmountExceeded =
      Number(amountOfUnits) >
      Number(selectedAssetData?.portfolio.assetBalance?.value);

    setAmountInputErrors({
      minWithdrawalAmountNotReached: !isMinWithdrawalReached,
      portfolioAmountExceeded: isPortfolioAmountExceeded,
    });
  };

  const changeAmountValueType = useCallback(
    (newType: AmountFor) => {
      if (amountInputValue.length > 0) {
        let newValue = '';

        newValue =
          (newType === 'FIAT'
            ? valueFromAssetToFiat(amountInputValue, selectedAssetData?.price)
            : valueFromFiatToAsset(
                amountInputValue,
                selectedAssetData?.price,
                selectedNetworkData?.maximumTransactionPrecision,
              )
          )?.value ?? '0';

        setAmountInputValue(newValue ?? '');
      }

      setInputValueType(newType);
    },
    [amountInputValue, selectedAssetData],
  );

  return {
    amountInputValue,
    amountInputValueType,
    amountInputErrors,
    validateInputAndCheckForThresholdsErrors,
    changeAmountValueType,
  };
};
