'use client';

import { ReactNode, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { CollapsibleHeader, CollapsibleHeaderProps } from './CollapsibleHeader';

export interface CollapsibleProps {
  internal: {
    className?: string;
  };
  external: {
    open?: boolean;
    header: CollapsibleHeaderProps;
    children: ReactNode;
  };
}

export const Item = ({
  className,
  open = false,
  header,
  children,
}: CollapsibleProps['internal'] & CollapsibleProps['external']) => {
  const [isCollapseOpen, setCollapseOpen] = useState(open);

  return (
    <Collapsible.Root
      className={className}
      open={isCollapseOpen}
      onOpenChange={setCollapseOpen}
      data-testid="collapsible"
    >
      <CollapsibleHeader {...header} />
      <Collapsible.Content data-element="content" data-testid="content">
        {children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
