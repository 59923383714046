import { useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { defaultLocale } from '@bts-web/utils-lokalise';
import {
  getRoundedPrecision,
  intlAssetValueFormatting,
  MaximumFractionDigits,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { type TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { CheckboxToggle } from '../../../../common/components/CheckboxToggle/CheckboxToggle';
import { ButtonBase } from '../../../../common/components/ButtonBase/ButtonBase';
import { NetworkTransfer } from '../../../../common/components/NetworkTransfer/NetworkTransfer';
import { TransfersSendStepsIds, DestinationTagValues } from '../../../types';
import { valueFromFiatToAsset, valueFromAssetToFiat } from '../../../../common';

interface ConfirmationScreenProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
  locale: typeof defaultLocale;
}

const containerStyles = css({
  paddingTop: 'medium',
  paddingBottom: 'extra_small_2',
  display: 'flex',
  flexDirection: 'column',
  gap: 'large',
  height: '100%',
});

const transactionAmountStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 'extra_small_2',
});

const amountStyle = css({
  fontSize: 'display.large_medium',
  fontWeight: 'display.large_medium',
  letterSpacing: 'display.large_medium',
  lineHeight: 'display.large_medium',
});

const destinationStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 'extra_small_3',

  '& > span:first-child': {
    fontSize: 'title.large',
    fontWeight: 'title.large',
    lineHeight: 'title.large',
    letterSpacing: 'title.large',
    color: 'neutrals.text_primary',

    '& > strong': {
      fontSize: 'label.large_bold',
      fontWeight: 'label.large_bold',
      lineHeight: 'label.large_bold',
      letterSpacing: 'label.large_bold',
    },
  },
  '& > span:last-child': {
    fontSize: 'body.medium',
    fontWeight: 'body.medium',
    lineHeight: 'body.medium',
    letterSpacing: 'body.medium',
    color: 'neutrals.text_secondary',

    '& > strong': {
      fontSize: 'body.medium_medium',
      fontWeight: 'body.medium_medium',
      lineHeight: 'body.medium_medium',
      letterSpacing: 'body.medium_medium',
    },
  },
});

const paymentTotalsStyle = css({
  display: 'flex',
  flexDirection: 'column',
  paddingY: 'medium',
  gap: 'large',
});

const summaryAddressStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small_2',
});

const summaryAddressRowStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: 'neutrals.text_secondary',
  fontSize: 'body.medium_medium',
  fontWeight: 'body.medium_medium',
  lineHeight: 'body.medium_medium',
  letterSpacing: 'body.medium_medium',
});

const totalAmountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
});

const totalLabel = css({
  color: 'neutrals.text_primary',
  fontSize: 'headline.medium',
  fontWeight: 'headline.medium',
  lineHeight: 'headline.medium',
  letterSpacing: 'headline.medium',
});

const totalSublabel = css({
  color: 'neutrals.text_primary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  lineHeight: 'body.medium',
  letterSpacing: 'body.medium',
});

const totalAmountValues = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small_2',
  textAlign: 'end',
});

const transferToggleTextStyle = css({
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  color: 'neutrals.text_primary',
});

const ConfirmationScreen = ({
  state,
  locale,
  translations,
}: ConfirmationScreenProps) => {
  const [isTransferButtonActive, setIsTransferButtonActive] = useState(false);

  const {
    selectedAssetData,
    selectedNetworkData,
    createdWithdrawalOfferData,
    changeToPageAndBindActions,
    assetAmountType,
  } = state;

  const addressValue = createdWithdrawalOfferData?.address?.address;

  const displayedAmount =
    assetAmountType === 'FIAT'
      ? createdWithdrawalOfferData?.fiatAmount
      : createdWithdrawalOfferData?.assetAmount;

  const displayedAmountFee =
    assetAmountType === 'FIAT'
      ? createdWithdrawalOfferData?.fiatAmountFee
      : createdWithdrawalOfferData?.assetFee;

  const totalAmount: PrecisionFloat = {
    value: (
      Number(displayedAmount?.value) + Number(displayedAmountFee?.value)
    ).toString(),
    precision:
      assetAmountType === 'FIAT'
        ? MaximumFractionDigits.FIAT
        : Math.max(
            Number(displayedAmount?.precision),
            Number(displayedAmountFee?.precision),
          ),
  };

  const convertedTotalAmount: PrecisionFloat =
    assetAmountType === 'FIAT'
      ? valueFromFiatToAsset(totalAmount.value, selectedAssetData?.price)
      : valueFromAssetToFiat(totalAmount.value, selectedAssetData?.price);

  return (
    <div className={containerStyles}>
      <NetworkTransfer
        assetData={selectedAssetData}
        networkData={selectedNetworkData}
      />

      <div className={transactionAmountStyle}>
        <span className={amountStyle}>
          ≈{' '}
          {intlAssetValueFormatting(
            Number(getRoundedPrecision(displayedAmount)),
            {
              locale,
              currency: assetAmountType === 'FIAT' ? 'EUR' : undefined,
              fractionDigits:
                assetAmountType === 'FIAT'
                  ? MaximumFractionDigits.FIAT
                  : displayedAmount?.precision,
            },
          )}{' '}
          {assetAmountType === 'ASSET' && selectedAssetData?.symbol}
        </span>

        <div className={destinationStyle}>
          <span>
            {translations.cryptoWithdrawalConfirmationToRecipient}
            {/* TODO show "external wallet" until wallet name info is available */}
            <strong>{translations.externalWallet}</strong>
          </span>
          <span>
            {translations.cryptoWithdrawalConfirmationOnNetwork}
            <strong>{selectedNetworkData?.name}</strong>
          </span>
        </div>
      </div>

      <div className={paymentTotalsStyle}>
        <div className={summaryAddressStyle}>
          {/* TODO show this when wallet info is available */}
          {/*
          <div className={summaryAddressRowStyle}>
            <span>Wallet</span>
            <span>Laura's Wallet</span>
          </div>
          */}

          <div className={summaryAddressRowStyle}>
            <span>{translations.address}</span>
            <span>
              {addressValue?.substring(0, 10)}
              ....
              {addressValue?.substring(addressValue?.length - 8)}
            </span>
          </div>

          {selectedNetworkData?.destinationTag && (
            <div className={summaryAddressRowStyle}>
              <span>
                {
                  translations[
                    selectedNetworkData?.destinationTag as DestinationTagValues
                  ]
                }
              </span>
              <span>
                {createdWithdrawalOfferData?.address?.destinationTag ??
                  translations.memoEmpty}
              </span>
            </div>
          )}

          <div className={summaryAddressRowStyle}>
            <span>{translations.transactionFees}</span>
            <span>
              {intlAssetValueFormatting(
                Number(getRoundedPrecision(displayedAmountFee)),
                {
                  locale,
                  currency: assetAmountType === 'FIAT' ? 'EUR' : undefined,
                  fractionDigits:
                    assetAmountType === 'FIAT'
                      ? MaximumFractionDigits.FIAT
                      : displayedAmountFee?.precision,
                },
              )}{' '}
              {assetAmountType === 'ASSET' && selectedAssetData?.symbol}
            </span>
          </div>
        </div>

        <div className={totalAmountStyle}>
          <span className={totalLabel}>{translations.total}</span>

          <div className={totalAmountValues}>
            <span className={totalLabel}>
              ≈{' '}
              {intlAssetValueFormatting(
                Number(getRoundedPrecision(totalAmount)),
                {
                  locale,
                  currency: assetAmountType === 'FIAT' ? 'EUR' : undefined,
                  fractionDigits:
                    assetAmountType === 'FIAT'
                      ? MaximumFractionDigits.FIAT
                      : totalAmount?.precision,
                },
              )}{' '}
              {assetAmountType === 'ASSET' && selectedAssetData?.symbol}
            </span>

            <span className={totalSublabel}>
              {intlAssetValueFormatting(
                Number(getRoundedPrecision(convertedTotalAmount)),
                {
                  locale,
                  fractionDigits: convertedTotalAmount?.precision,
                  currency: assetAmountType === 'ASSET' ? 'EUR' : undefined,
                },
              )}{' '}
              {assetAmountType === 'FIAT' && selectedAssetData?.symbol}
            </span>
          </div>
        </div>
      </div>

      <div
        className={css({
          gap: 'large',
          display: 'flex',
          flexDirection: 'column',
          marginTop: 'auto',
          alignItems: 'center',
        })}
      >
        <div
          className={css({
            gap: 'medium',
            display: 'flex',
          })}
        >
          <p className={transferToggleTextStyle}>
            {translations.cryptoWithdrawalConfirmationText}
          </p>

          <CheckboxToggle
            checked={isTransferButtonActive}
            onChange={() => {
              setIsTransferButtonActive((activeState) => !activeState);
            }}
          />
        </div>

        <ButtonBase
          disabled={!isTransferButtonActive}
          onClick={() => {
            changeToPageAndBindActions({
              pageId: TransfersSendStepsIds.TwoFactorAuth,
              newBackAction: {
                pageId: TransfersSendStepsIds.Confirmation,
                type: 'page',
              },
            });
          }}
        >
          {translations.transfer}
        </ButtonBase>
      </div>
    </div>
  );
};

export { ConfirmationScreen };
