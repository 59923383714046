import {
  TransfersCommonStepsIds,
  TransfersDepositStepsIds,
  TransfersSendStepsIds,
} from '../../../types';
import { type PageId } from '../../../utils/useTransfersNextStepState.client';

export const getNextPageForGoingBack = (
  currentPageId: PageId,
  hasMultipleNetworks: boolean,
): PageId | undefined => {
  const nextBackPageMapping = {
    [TransfersCommonStepsIds.ChooseAsset]: undefined,
    [TransfersCommonStepsIds.ChooseNetwork]:
      TransfersCommonStepsIds.ChooseAsset,
    [TransfersCommonStepsIds.AssetAndNetworkSummary]: hasMultipleNetworks
      ? TransfersCommonStepsIds.ChooseNetwork
      : TransfersCommonStepsIds.ChooseAsset,
    [TransfersDepositStepsIds.DepositWallet]:
      TransfersDepositStepsIds.DepositWarning,
    [TransfersDepositStepsIds.DepositWarning]:
      TransfersCommonStepsIds.AssetAndNetworkSummary,
    [TransfersSendStepsIds.Warning]:
      TransfersCommonStepsIds.AssetAndNetworkSummary,
    [TransfersSendStepsIds.ChooseRecipient]: TransfersSendStepsIds.Warning,
    [TransfersSendStepsIds.MemoMissingWarning]:
      TransfersSendStepsIds.ChooseRecipient,
    [TransfersSendStepsIds.ChooseAmount]: TransfersSendStepsIds.ChooseRecipient,
    [TransfersSendStepsIds.Confirmation]: TransfersSendStepsIds.ChooseAmount,
    [TransfersSendStepsIds.TwoFactorAuth]: TransfersSendStepsIds.Confirmation,
  };

  return nextBackPageMapping[currentPageId];
};
