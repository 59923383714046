'use client';

import { useState } from 'react';
import { TransfersSectionVariants } from '../types';

export const useTransfersState = () => {
  const [currentSection, setCurrentSection] =
    useState<TransfersSectionVariants>(TransfersSectionVariants.Deposit); // starts with deposit tab pre-selected when opening drawer

  return {
    currentSection,
    setCurrentSection,
  };
};
