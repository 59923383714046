import { css } from '@bts-web/utils-style-engine';
import { FC, ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react';

interface ITransfersLayoutProps {
  topRegion?: ReactNode;
  middleScrollableRegion?: ReactNode;
  wrapperProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

const TransfersLayout: FC<ITransfersLayoutProps> = ({
  topRegion,
  middleScrollableRegion,
  wrapperProps,
}) => {
  return (
    <div
      className={css({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      })}
      {...wrapperProps}
    >
      {topRegion && <div className={css({})}>{topRegion}</div>}

      {middleScrollableRegion && (
        <div
          className={css({
            flex: 1,
            overflowY: 'auto',
            px: 'medium',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            pb: 'small',
          })}
          data-testid="middle-scrollable-region"
        >
          {middleScrollableRegion}
        </div>
      )}
    </div>
  );
};

export { TransfersLayout };
