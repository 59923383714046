import { Locale } from '@bts-web/utils-lokalise';

export const convertToLocaleDateString = (
  date: string,
  locale: Locale,
  options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
) => {
  return new Date(date.split('T')[0]).toLocaleDateString(locale, options);
};
