'use client';

import { forwardRef, ReactNode } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Icon } from '@bts-web/design-system/component/icon';

export interface CollapsibleHeaderProps {
  LeftContentIndicatorSlot: ReactNode;
  IconComponent?: ReactNode;
  RightContentIndicatorSlot?: string;
}

export const CollapsibleHeader = forwardRef<
  HTMLButtonElement,
  CollapsibleHeaderProps
>((props, forwardedRef) => {
  const {
    IconComponent,
    LeftContentIndicatorSlot,
    RightContentIndicatorSlot,
    ...restProps
  } = props;

  return (
    <Collapsible.Trigger
      {...restProps}
      data-element="trigger"
      ref={forwardedRef}
      data-testid="trigger"
      asChild
    >
      <button>
        <span data-element="left-content-slot-wrap">
          {LeftContentIndicatorSlot}
        </span>

        <span data-element="right-content-slot-wrap">
          {RightContentIndicatorSlot}
          <span aria-hidden data-element="icon" data-testid="icon">
            {IconComponent || <Icon icon="chevron-down" size="16" />}
          </span>
        </span>
      </button>
    </Collapsible.Trigger>
  );
});
