'use client';

import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import {
  TAssetForTransferArray,
  TransfersCommonStepsIds,
  TransfersDepositStepsIds,
  TransfersSectionVariants,
  TransfersSendStepsIds,
} from '../../../types';
import { TransferEntityPickerEntry } from '../TransferEntityPickerEntry/TransferEntityPickerEntry';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { ButtonBase } from '../../../../common/components/ButtonBase/ButtonBase';
import { AlertBox } from '../../../../common/components/AlertBox/AlertBox';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { useAppNotification } from '../../../../notifications/NotificationContextProvider';

export interface IAssetAndNetworkPageProps {
  assetList: TAssetForTransferArray;
  state: TTransfersNextStepStateWithActions;
  variant: TransfersSectionVariants;
  translations: TCryptoTransferTranslations;
}

const selectedAssetSectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const entityPickerWrapStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  flex: 1,
});

const AssetAndNetworkPage: FC<IAssetAndNetworkPageProps> = ({
  assetList,
  state,
  variant,
  translations,
}) => {
  const { setAppNotification } = useAppNotification();

  const { t } = useClientTranslation();

  const selectedAsset = state.selectedAssetData?.id
    ? assetList.find((asset) => asset.id === state.selectedAssetData?.id)
    : null;

  const selectedNetwork = state.selectedNetworkData
    ? selectedAsset?.networkInformation?.networks?.find(
        (network) => network?.id === state.selectedNetworkData?.id,
      )
    : null;

  const canContinue = !!selectedAsset?.id && !!selectedNetwork?.id;

  const showInfoBox =
    selectedAsset?.networkInformation?.networks?.length &&
    selectedAsset?.networkInformation?.networks.length > 1;

  const onDepositClick = () => {
    if (!selectedNetwork?.isDepositAllowed) {
      setAppNotification({
        title: t('deposit_not_allowed', {
          assetName: selectedAsset?.name,
          networkName: selectedNetwork?.name,
        }),
        visual: 'error',
        customDuration: 3000,
      });

      return;
    }

    state.changeToPageAndBindActions({
      pageId: TransfersDepositStepsIds.DepositWarning,
      newBackAction: {
        pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
        type: 'page',
      },
    });
  };

  const onWithdrawalClick = () => {
    if (!selectedNetwork?.isWithdrawalAllowed) {
      setAppNotification({
        title: t('withdrawal_not_allowed', {
          assetName: selectedAsset?.name,
          networkName: selectedNetwork?.name,
        }),
        visual: 'error',
        customDuration: 3000,
      });

      return;
    }

    const assetBalanceValue = Number(
      selectedAsset?.portfolio?.assetBalance?.value,
    );

    const minWithdrawalThresholdValue = Number(
      selectedNetwork?.minWithdrawalThreshold?.value,
    );

    if (assetBalanceValue < minWithdrawalThresholdValue) {
      setAppNotification({
        title: t('crypto_min_withdrawal_error_toast_title'),
        subtitle: t('crypto_min_withdrawal_error_toast_subtitle', {
          minamount: minWithdrawalThresholdValue,
          assetsymbol: selectedAsset?.symbol,
        }),
        visual: 'error',
        customDuration: 5000,
      });

      return;
    }

    state.changeToPageAndBindActions({
      pageId: TransfersSendStepsIds.Warning,
      newBackAction: {
        pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
        type: 'page',
      },
    });
  };

  return (
    <section
      aria-label="selected asset and network summary"
      className={selectedAssetSectionStyles}
    >
      <div className={entityPickerWrapStyles}>
        {selectedAsset ? (
          <TransferEntityPickerEntry
            title={selectedAsset.name ?? ''}
            type="pickerParentWithValue"
            imageUrl={selectedAsset.logoUrl ?? undefined}
            onPick={() =>
              state.changeToPageAndBindActions({
                pageId: TransfersCommonStepsIds.ChooseAsset,
                newBackAction: {
                  pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
                  type: 'page',
                },
              })
            }
            heading={translations.cryptocurrency}
          />
        ) : (
          <TransferEntityPickerEntry
            title={translations.selectAsset}
            type="pickerParentWithValue"
            onPick={() =>
              state.changeToPageAndBindActions({
                pageId: TransfersCommonStepsIds.ChooseAsset,
                newBackAction: {
                  pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
                  type: 'page',
                },
              })
            }
            heading={translations.cryptocurrency}
          />
        )}

        {selectedNetwork ? (
          <TransferEntityPickerEntry
            title={selectedNetwork.name ?? ''}
            type="pickerParentWithValue"
            imageUrl={selectedNetwork.logoUrl ?? undefined}
            onPick={() =>
              state.changeToPageAndBindActions({
                pageId: TransfersCommonStepsIds.ChooseNetwork,
                newBackAction: {
                  pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
                  type: 'page',
                },
              })
            }
            heading={translations.network}
          />
        ) : (
          <TransferEntityPickerEntry
            title={translations.selectNetwork}
            type="pickerParentWithValue"
            onPick={() =>
              state.changeToPageAndBindActions({
                pageId: TransfersCommonStepsIds.ChooseNetwork,
                newBackAction: {
                  pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
                  type: 'page',
                },
              })
            }
            heading={translations.network}
          />
        )}

        {selectedNetwork && selectedAsset && showInfoBox ? (
          <AlertBox variant="info">
            {translations.inline_warning_asset_network_lost}
          </AlertBox>
        ) : null}
      </div>

      <div
        className={css({
          pt: 'small',
          display: 'flex',
          justifyContent: 'center',
        })}
      >
        {variant === TransfersSectionVariants.Deposit ? (
          <ButtonBase disabled={!canContinue} onClick={onDepositClick}>
            {translations.view_wallet_address_button}
          </ButtonBase>
        ) : (
          <ButtonBase disabled={!canContinue} onClick={onWithdrawalClick}>
            {translations.next}
          </ButtonBase>
        )}
      </div>
    </section>
  );
};

export { AssetAndNetworkPage };
