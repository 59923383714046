import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { SearchBar } from '../../../common';
import { useTransfersAssetsListContext } from '../TransfersAssetListContext/TransfersAssetListContext';
import { MAXIMUM_SEARCH_TERM_LENGTH } from '../../../config';

export interface CryptoTransfersSearchBarTranslations {
  searchAssets: string;
}

interface CryptoTransfersSearchBarProps {
  placeholder: string;
  disabled?: boolean;
}

export const CryptoTransfersSearchBar: FC<CryptoTransfersSearchBarProps> = ({
  placeholder,
  disabled,
}) => {
  const { onInputChange, assetFilterSearchValue } =
    useTransfersAssetsListContext();

  return (
    <div className={css({ px: 'medium', py: 'extra_small' })}>
      <SearchBar
        value={assetFilterSearchValue}
        searchIcon={<Icon size="24" icon="search" />}
        dismissIcon={<Icon size="24" icon="dismiss" />}
        placeholder={placeholder}
        onChange={onInputChange}
        onClear={() => {
          onInputChange('');
        }}
        maxLength={MAXIMUM_SEARCH_TERM_LENGTH}
        disabled={disabled}
      />
    </div>
  );
};
