import { FC } from 'react';
import { css, stack } from '@bts-web/utils-style-engine';
import { AlertBox } from '../../../../common/components/AlertBox/AlertBox';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TransferWarningHeaderBox } from '../../Reusable/TransferWarningHeaderBox/TransferWarningHeaderBox';
import { TransferWarningInformation } from './TransferWarningInformation';
import { ButtonBase } from '../../../../common/components/ButtonBase/ButtonBase';
import { TAssetForTransferArray, TransfersSendStepsIds } from '../../../types';

interface TransferWarningScreenProps {
  assetList: TAssetForTransferArray;
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const TransferWarningScreen: FC<TransferWarningScreenProps> = ({
  assetList,
  state,
  translations,
}) => {
  const selectedAsset = state.selectedAssetData
    ? assetList.find((asset) => asset.id === state.selectedAssetData?.id)
    : null;

  const selectedNetwork = state.selectedNetworkData
    ? selectedAsset?.networkInformation?.networks?.find(
        (network) => network?.id === state.selectedNetworkData?.id,
      )
    : null;

  const canContinue = !!selectedAsset?.id && !!selectedNetwork?.id;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small',
        flex: 1,
      })}
    >
      <TransferWarningHeaderBox
        title={translations.cryptoWithdrawalTitle}
        iconName="eye"
      />
      <TransferWarningInformation translations={translations} />

      <AlertBox variant="warning">
        {translations.cryptoWithdrawalFraudWarning}
      </AlertBox>

      <div
        className={stack({
          gap: 'small',
          pt: 'medium',
          alignItems: 'center',
        })}
      >
        <ButtonBase
          visual={'accent'}
          disabled={!canContinue}
          onClick={() => {
            state.changeToPageAndBindActions({
              pageId: TransfersSendStepsIds.ChooseRecipient,
              newBackAction: {
                pageId: TransfersSendStepsIds.Warning,
                type: 'page',
              },
            });
          }}
        >
          {translations.cryptoWithdrawalCtaConfirm}
        </ButtonBase>

        <ButtonBase
          visual="ghost"
          onClick={() => {
            state.twoStepClose();
          }}
        >
          {translations.cryptoWithdrawalCtaCancel}
        </ButtonBase>
      </div>
    </div>
  );
};

export { TransferWarningScreen };
