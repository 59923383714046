import { FC } from 'react';
import Image from 'next/image';
import { css } from '@bts-web/utils-style-engine';
import { Locale } from '@bts-web/utils-lokalise';
import {
  PrecisionFloat,
  getPrecisionFloatValue,
} from '@bts-web/utils-formatting';
import { TradeScreenTranslations } from '../../../../utils';
import { BelowInputInfoTable, Collapsible } from '../../../../../common';
import taxInfo from './tax-info.svg';

const taxBoxPrimaryContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small',
  alignItems: 'start',
  padding: 'small',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'warning.stroke_primary',
  borderRadius: 8,
  textAlign: 'start',
});

type TradeTaxSummaryProps = {
  currency: string;
  isTaxServiceOffline?: boolean;
  translations: TradeScreenTranslations;
  currentLocale: Locale;
  taxAmount: PrecisionFloat;
  fiatAmount: PrecisionFloat;
};

const getAmount = ({
  locale,
  amount,
  currency,
}: {
  currency?: string;
  locale: Locale;
  amount: PrecisionFloat;
}) => {
  const amountValue = getPrecisionFloatValue(amount);

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(Number(amountValue));
};

export const TradeTaxSummary: FC<TradeTaxSummaryProps> = ({
  currency,
  currentLocale,
  fiatAmount,
  isTaxServiceOffline,
  taxAmount,
  translations,
}) => {
  return (
    <Collapsible
      header={{
        LeftContentIndicatorSlot: (
          <span className={css({ display: 'flex', gap: 'extra_small_3' })}>
            <span
              className={css({
                ...(isTaxServiceOffline
                  ? {
                      color: 'warning.text_primary',
                    }
                  : {}),
              })}
            >
              {translations.taxDetails}
            </span>
            {isTaxServiceOffline && (
              <Image
                src={taxInfo}
                alt="tax info icon"
                width={14}
                height={14}
                data-testid="offline-tax-icon"
              />
            )}
          </span>
        ),
      }}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'medium',
        })}
      >
        <BelowInputInfoTable
          variant={isTaxServiceOffline ? 'warning' : 'primary'}
          items={[
            {
              title: translations.taxWithHeld,
              value: getAmount({
                amount: taxAmount,
                locale: currentLocale,
                currency,
              }),
            },
            {
              title: translations.youReceive,
              value: getAmount({
                amount: fiatAmount,
                locale: currentLocale,
                currency,
              }),
            },
          ]}
        />
        {isTaxServiceOffline ? (
          <div className={taxBoxPrimaryContainerStyles}>
            <p
              className={css({
                color: 'warning.text_primary',
                fontSize: 'label.medium_medium',
                fontWeight: 'label.medium_medium',
                lineHeight: 'label.medium_medium',
                letterSpacing: 'label.medium_medium',
              })}
            >
              {translations.taxSystemOfflineWarningTitle}
            </p>
            <p
              className={css({
                color: 'neutrals.text_primary',
                fontSize: 'body.medium',
                fontWeight: 'body.medium',
                lineHeight: 'body.medium',
                letterSpacing: 'body.medium',
              })}
            >
              {translations.taxSystemOfflineWarningSubtitle}
            </p>
          </div>
        ) : (
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: 'extra_small',
              py: 'medium',
            })}
          >
            <p>{translations.cryptoTaxInfoText1}</p>
            <p>{translations.cryptoTaxInfoText2}</p>
          </div>
        )}
      </div>
    </Collapsible>
  );
};
