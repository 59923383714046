import { FC } from 'react';
import {
  Bar as DesignBar,
  BarProps,
} from '@bts-web/design-system/component/bar';
import { css } from '@bts-web/utils-style-engine';

const PillBar: FC<BarProps['external'] & { size?: 'medium' | 'small' }> = ({
  size = 'medium',
  ...rest
}) => {
  return (
    <DesignBar
      barItemClassName={css({
        alignItems: 'center',
        backgroundColor: 'neutrals.card_fill_primary',
        borderWidth: '1px',
        borderColor: 'neutrals.stroke_secondary',
        borderRadius: '50px',
        color: 'neutrals.text_primary',
        cursor: 'pointer',
        display: 'flex',
        flexShrink: 0,
        fontSize: 'body.medium',
        justifyContent: 'center',
        gap: 'extra_small_3',
        '&:hover': {
          backgroundColor: 'neutrals.fill_quinary',
          border: 'neutrals.stroke_primary',
        },
        "&[data-state='on'], &:hover[data-state='on']": {
          borderColor: 'neutrals.stroke_primary',
          backgroundColor: 'neutrals.fill_primary',
          color: 'neutrals.text_primary_inverted',
        },
        '&:disabled': {
          backgroundColor: 'neutrals.fill_disabled',
          borderColor: 'neutrals.stroke_secondary_disabled',
          color: 'neutrals.text_disabled',
          pointerEvents: 'none',
        },
        ...(size === 'medium'
          ? {
              paddingTop: 'extra_small_2',
              paddingBottom: 'extra_small_2',
              paddingInlineStart: 'small',
              paddingInlineEnd: 'small',
            }
          : {}),
        ...(size === 'small'
          ? {
              paddingTop: 'extra_small_3',
              paddingBottom: 'extra_small_3',
              paddingInlineStart: 'extra_small_2',
              paddingInlineEnd: 'extra_small_2',
            }
          : {}),
      })}
      className={css({
        alignItems: 'start',
        display: 'inline-flex',
        gap: 'extra_small_2',
        maxWidth: '100%',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      })}
      {...rest}
    />
  );
};

export { PillBar };
