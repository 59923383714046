'use client';

import { useClientUserInfo, NotificationsBell } from '../../../../common';

export const ReportsAndStatementsNotifications = () => {
  const {
    notifications: { unreadCount },
  } = useClientUserInfo();

  return (
    <NotificationsBell count={unreadCount} href="/reports-and-statements" />
  );
};
