'use client';

import { useRef, useState, useEffect, ReactElement } from 'react';
import Highcharts, { Chart, Options } from 'highcharts/highstock';
import HighchartsReact, {
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import { Locale } from '@bts-web/utils-lokalise';
import HighchartsAnnotations from 'highcharts/modules/annotations';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { ConditionalValue } from '@bts-web/utils-style-engine';
import { intlAssetValueFormatting } from '@bts-web/utils-formatting';
import { TabBarMini } from '../TabBarMini/TabBarMini';
import { TimeAmounts } from '../utils';
import {
  getMedian,
  registerRangeSelectorButtonsEvents,
  type DataSeriesEntry,
  type RangeSelectorConfigItem,
  RangeSelectorButtonsDomIds,
} from '../utils';
import { TimelineBar } from '../TimelineBar/TimelineBar';

interface ExtendedRuntimeChartSeries extends Highcharts.Series {
  processedYData: number[];
  processedXData: number[];
}

type AreaChartSize = 'small' | 'default';

export interface AreaChartProps {
  internal: {
    className: string;
    rangeSelectorClassName?: string;
    timelineClassName?: string;
  };
  external: {
    locale: Locale;
    digitPrecision?: number;
    seriesData: DataSeriesEntry[];
    rangeSelectorsConfig?: RangeSelectorConfigItem[];
    rangeSelectCallback?: (selectedTimeRange: TimeAmounts) => void;
    hasSingleDataSet?: boolean;
    showMinMax?: boolean;
    timelineLabels?: Array<string>;
    isLoading?: boolean;
    isDisabled?: boolean;
    loadingContentSlot?: ReactElement;
    showTimeframe?: boolean;
    size?: ConditionalValue<AreaChartSize>;
  };
}

const emptyFn = () => {
  return;
};

const chartHeightBySize: Record<
  AreaChartSize,
  { mobile: number; desktop: number }
> = {
  default: {
    desktop: 440,
    mobile: 280,
  },
  small: {
    desktop: 120,
    mobile: 120,
  },
};

const AreaChart = ({
  className,
  locale,
  digitPrecision,
  rangeSelectorClassName,
  timelineClassName,
  seriesData,
  isDisabled,
  rangeSelectorsConfig,
  rangeSelectCallback,
  hasSingleDataSet = false,
  showMinMax = false,
  timelineLabels,
  isLoading,
  loadingContentSlot,
  showTimeframe,
  size = 'default',
  ...rest
}: AreaChartProps['internal'] & AreaChartProps['external']) => {
  // Extract data attributes
  const dataAttributes = Object.entries(rest).reduce(
    (acc, [key, value]) => {
      if (key.startsWith('data-')) {
        acc[key] = value as string | number | boolean;
      }

      return acc;
    },
    {} as Record<string, string | number | boolean>,
  );

  if (typeof Highcharts === 'object') {
    HighchartsAnnotations(Highcharts);

    HighchartsAccessibility(Highcharts);
  }

  // this trick is applied so the responsive options use the screen width rather than the chart width
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const chartRef = useRef<HighchartsReactRefObject>(null);

  const [chartOptions, setChartOptions] = useState<Options>(
    getInitialChartOptions(),
  );

  useEffect(() => {
    updateChartOptions();
  }, [seriesData, showMinMax]); // eslint-disable-line react-hooks/exhaustive-deps

  function getInitialChartOptions(): Options {
    const chartHeight = chartHeightBySize[size as AreaChartSize];

    return {
      chart: {
        renderTo: chartContainerRef.current as HTMLElement,
        type: 'area',
        styledMode: true,
        // to make space for the baloons in case they are at the very top edge
        zooming: { mouseWheel: { enabled: false } },
        events: {
          load: function () {
            if (hasSingleDataSet && rangeSelectorsConfig) {
              registerRangeSelectorButtonsEvents(
                this as unknown as Chart,
                rangeSelectorsConfig,
                setDatasetMinMaxPoints,
              );
            }
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              // this trick is applied so the responsive options use the screen width rather than the chart width
              callback: function () {
                return window.innerWidth <= 1022;
              },
            },
            chartOptions: {
              chart: {
                marginBottom: 0,
                spacingBottom: 0,
                spacingTop: 0,
                marginLeft: 0,
                marginRight: 0,
                height: chartHeight.mobile,
              },
            },
          },
          {
            condition: {
              // this trick is applied so the responsive options use the screen width rather than the chart width
              callback: function () {
                return window.innerWidth > 1022;
              },
            },
            chartOptions: {
              chart: {
                spacingTop: 0,
                marginBottom: 30,
                spacingBottom: 30,
                height: chartHeight.desktop,
                marginLeft: 0,
                marginRight: 0,
              },
            },
          },
        ],
      },
      accessibility: { enabled: true },
      rangeSelector: { enabled: false },
      navigator: { enabled: false },
      exporting: { enabled: false },
      xAxis: {
        labels: { formatter: () => '' },
        visible: true,
        lineWidth: 0,
        tickLength: 0,
        lineColor: 'transparent',
        gridLineColor: 'transparent',
        className: 'hidden-axis', // because of the styled mode
        opposite: false, // Ensure the x-axis is at the bottom
      },
      yAxis: {
        visible: false,
      },
      plotOptions: {
        series: { enableMouseTracking: false },
        area: { marker: { enabled: false } },
      },
      scrollbar: { enabled: false },
      credits: { enabled: false },
      series: [
        { data: [[0, 0]], type: 'area' },
        { data: [[0, 0]], type: 'line' },
      ],
    };
  }

  function updateChartOptions() {
    const dataMedian = getMedian(seriesData);

    const medianSeries = seriesData.map(([timestamp]) => [
      timestamp,
      dataMedian,
    ]);

    if (seriesData.length === 2) {
      setChartOptions((prevState) => ({
        ...prevState,
        series: [{ data: seriesData, type: 'area', threshold: null }],
        xAxis: { visible: false },
      }));
    } else if (seriesData.length > 2) {
      const [minDate, maxDate] = getDateRange(seriesData);

      const range = maxDate - minDate;

      setChartOptions((prevState) => ({
        ...prevState,
        series: [
          { data: seriesData, type: 'area', threshold: null },
          { data: medianSeries, type: 'line', threshold: null },
        ],
        ...(showTimeframe && { xAxis: getXAxisOptions(range) }),
      }));
    }

    if (showMinMax) {
      setTimeout(setDatasetMinMaxPoints, 500);
    }
  }

  function getDateRange(data: DataSeriesEntry[]): [number, number] {
    const minDate = new Date(data[0][0]).getTime();

    const maxDate = new Date(data[data.length - 1][0]).getTime();

    return [minDate, maxDate];
  }

  function getXAxisOptions(range: number): Partial<Highcharts.XAxisOptions> {
    return {
      tickPositions: getDisplayedLabels(seriesData),
      offset: -6,
      labels: {
        useHTML: true,
        autoRotation: undefined,
        align: 'center',
        formatter: function () {
          return formatXAxisLabel(this, range);
        },
      },
      events: {
        afterSetExtremes: function () {
          this.chart.redraw(); // Force redraw after extremes are set
        },
      },
      opposite: false, // Ensure the x-axis is at the bottom
    };
  }

  function formatXAxisLabel(
    context: Highcharts.AxisLabelsFormatterContextObject,
    range: number,
  ): string {
    let format = '%b %Y';

    if (range <= 24 * 3600 * 1000) format = '%H:%M';
    else if (range <= 7 * 24 * 3600 * 1000) format = '%a %d';
    else if (range <= 30 * 24 * 3600 * 1000) format = '%b %d';

    const label = Highcharts.dateFormat(format, context.value as number);

    const className = context.isFirst
      ? 'first'
      : context.isLast
        ? 'last'
        : 'default';

    return `<span class="highcharts-label-${className}" style="position:relative; z-index:1; visibility: visible !important;">${label}</span>`;
  }

  function setDatasetMinMaxPoints(): void {
    const series = chartRef.current?.chart
      .series as ExtendedRuntimeChartSeries[];

    if (!series) return;

    const yData = series[0].processedYData.slice(1, -1) as number[];

    const yMin = Math.min(...yData);

    const yMax = Math.max(...yData);

    const minIndex = series[0].processedYData.indexOf(yMin);

    const maxIndex = series[0].processedYData.indexOf(yMax);

    const xMin = series[0].processedXData[minIndex];

    const xMax = series[0].processedXData[maxIndex];

    setChartOptions((prevState) => ({
      ...prevState,
      annotations: [
        {
          draggable: '',
          events: {
            add: emptyFn,
            afterUpdate: emptyFn,
            click: emptyFn,
            drag: emptyFn,
            remove: emptyFn,
          },
          labels: [
            createAnnotationLabel(xMin, yMin, 'Minimum'),
            createAnnotationLabel(xMax, yMax, 'Maximum'),
          ],
        },
      ] as Highcharts.AnnotationsOptions[],
    }));
  }

  function createAnnotationLabel(
    x: number,
    y: number,
    description: string,
  ): Highcharts.AnnotationsLabelsOptions {
    return {
      point: { x, xAxis: 0, y, yAxis: 0 },
      text: intlAssetValueFormatting(y, {
        currency: 'EUR',
        fractionDigits: digitPrecision,
        locale,
      }),
      align: 'left' as const,
      accessibility: { description },
    };
  }

  const tabBarMiniConfig = rangeSelectorsConfig?.map(
    ({ timeAmountId, text }) => ({
      timeAmountId,
      text,
      domId: RangeSelectorButtonsDomIds[timeAmountId],
    }),
  );

  return (
    <div
      ref={chartContainerRef}
      className={className}
      data-element-type={
        timelineLabels?.length ? 'with-timeline-bar' : 'with-tab-bar-mini'
      }
      data-testid="area-chart-container"
      {...dataAttributes}
    >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
        allowChartUpdate={true}
        ref={chartRef}
      />
      {isLoading && (
        <div data-element="loading" data-testid="loading">
          {loadingContentSlot || 'Loading...'}
        </div>
      )}
      {tabBarMiniConfig && (
        <TabBarMini
          className={rangeSelectorClassName as string}
          tabsConfig={tabBarMiniConfig}
          onClickCallback={rangeSelectCallback || emptyFn}
          isDisabled={seriesData.length === 0 || isDisabled}
        />
      )}
      {timelineLabels && (
        <TimelineBar
          className={timelineClassName as string}
          labels={timelineLabels}
        />
      )}
    </div>
  );
};

function getDisplayedLabels(dataset: Array<[number, number]>): number[] {
  const MAX_NUMBER_OF_LABELS = 6;

  const step = (dataset.length - 1) / (MAX_NUMBER_OF_LABELS - 1);

  return Array.from(
    { length: MAX_NUMBER_OF_LABELS },
    (_, i) => dataset[Math.round(i * step)][0],
  );
}

export { AreaChart };
