'use client';

import { FC } from 'react';
import {
  TabBreadcrumbs as DesignTabBreadcrumbs,
  TabBreadcrumbsProps,
} from '@bts-web/design-system/component/tab-breadcrumbs';
import { css } from '@bts-web/utils-style-engine';

type ThemedTabBreadcrumbsProps = TabBreadcrumbsProps['external'];

const tabBreadCrumbStyles = css({
  paddingTop: 'extra_small_3',
  paddingBottom: 'extra_small_3',
  paddingInlineStart: 'extra_small_2',
  paddingInlineEnd: 'extra_small_2',
  display: 'flex',
  alignItems: 'center',
  gap: 'medium',

  lg: {
    paddingTop: 'small',
    paddingBottom: 'small',
    paddingInlineStart: 'medium',
    paddingInlineEnd: 'medium',
  },
});

const breadCrumbStyles = css({
  display: 'flex',
  overflow: 'hidden',
  '& ol': {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    overflow: 'hidden',
    alignItems: 'center',
    gap: 'extra_small_2',
  },
  '& [data-element="separator"]': {
    color: 'neutrals.fill_tertiary',
    fontSize: 'title.medium_medium',
    fontWeight: 'title.medium_medium',
    lineHeight: 'title.medium_medium',
    letterSpacing: 'title.medium_medium',
    userSelect: 'none',
  },
  '& li': {
    display: 'flex',
    alignItems: 'center',
    px: 'extra_small_2',
    py: 'extra_small_4',
  },
  '& li > *': {
    color: 'neutrals.text_primary',
    display: 'inline',
    fontSize: 'body.medium_medium',
    fontWeight: 'body.medium_medium',
    lineHeight: 'body.medium_medium',
    letterSpacing: 'body.medium_medium',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& li:last-child': {
    overflow: 'hidden',
    borderRadius: '8px',
    bg: 'neutrals.fill_quaternary',
  },
  '& li:last-child > *': {
    flex: 1,
    minWidth: 0,
  },
});

const TabBreadcrumbs: FC<ThemedTabBreadcrumbsProps> = ({
  children,
  LeftComponent,
}) => {
  return (
    <DesignTabBreadcrumbs
      className={tabBreadCrumbStyles}
      breadCrumbsClassName={breadCrumbStyles}
      LeftComponent={LeftComponent}
    >
      {children}
    </DesignTabBreadcrumbs>
  );
};

export { TabBreadcrumbs };
