import { FC } from 'react';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { css, stack } from '@bts-web/utils-style-engine';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import { TransfersDepositStepsIds } from '../../../../types';
import { RedirectToAssetSelection } from '../redirects/RedirectToAssetSelection';
import { RedirectToNetworkSelection } from '../redirects/RedirectToNetworkSelection';
import { AlertBox, ButtonBase } from '../../../../../common';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';
import { TransferWarningHeaderBox } from '../../../Reusable/TransferWarningHeaderBox/TransferWarningHeaderBox';
import { DepositWarningInfoField } from './DepositWarningInfoField';

interface DepositWarningPageProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const DepositWarningPage: FC<DepositWarningPageProps> = ({
  state,
  translations,
}) => {
  const { t } = useClientTranslation();

  const { selectedAssetData, selectedNetworkData } = state;

  const onDepositClick = () => {
    state.changeToPageAndBindActions({
      pageId: TransfersDepositStepsIds.DepositWallet,
      newBackAction: {
        pageId: TransfersDepositStepsIds.DepositWarning,
        type: 'page',
      },
    });
  };

  if (!selectedAssetData) {
    return <RedirectToAssetSelection state={state} />;
  }

  if (!selectedNetworkData) {
    return <RedirectToNetworkSelection state={state} />;
  }

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'large',
        height: '100%',
        alignItems: 'center',
        pt: 'medium',
      })}
    >
      <TransferWarningHeaderBox
        iconName="send"
        title={translations.cryptoDepositDrawerTitle}
      />

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: 'extra_small_2',
        })}
      >
        <p>{translations.cryptoDepositDrawerSubtitle}</p>
        <div className={stack()}>
          <DepositWarningInfoField
            label={
              <>
                <span>{t('crypto_deposit_drawer_list_item_1_network_1')}</span>
                &nbsp;
                <span>
                  {t('crypto_deposit_drawer_list_item_1_network_2', {
                    networkname: selectedNetworkData?.name,
                  })}
                </span>
              </>
            }
          />
          <DepositWarningInfoField
            label={
              <>
                <span>{t('crypto_deposit_drawer_list_item_2')}</span>
                &nbsp;
                <span>
                  {selectedAssetData?.name} ({selectedAssetData.symbol})
                </span>
              </>
            }
          />
        </div>
      </div>
      <AlertBox variant="warning">
        {translations.inline_warning_asset_network_lost}
      </AlertBox>

      <ButtonBase onClick={onDepositClick}>
        {translations.iUnderstand}
      </ButtonBase>
    </div>
  );
};

export { DepositWarningPage };
