'use client';

import { FC, useMemo } from 'react';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { getAppConfig } from '@bts-web/core-app-config';
import { TTransfersNextStepStateWithActions } from '../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../utils/getCryptoTransferTranslations';
import { TransfersActionBar } from '../Reusable/ActionBar/TransfersActionBar';
import { AssetPickerList } from '../Reusable/AssetPickerList/AssetPickerList';
import { NetworkPickerList } from '../Reusable/NetworkPickerList/NetworkPickerList';
import { AssetAndNetworkPage } from '../Reusable/AssetAndNetworkPage/AssetAndNetworkPage';
import { TransfersLayout } from '../Reusable/TransfersLayout/TransfersLayout';
import { SendProgressBar } from './components/SendProgressBar/SendProgressBar';
import { useTransfersAssetsListContext } from '../TransfersAssetListContext/TransfersAssetListContext';
import { AssetsListSkeleton } from '../TransfersParent/TransfersSkeleton';
import {
  TransfersSendStepsIds,
  TransfersSectionVariants,
  TransfersCommonStepsIds,
} from '../../types';
import { ChooseRecipientScreen } from './ChooseRecipientScreen/ChooseRecipientScreen';
import { TransferWarningScreen } from './TransferWarningScreen/TransferWarningScreen';
import { MemoIsMissingWarningScreen } from './MemoIsMissingWarningScreen/MemoIsMissingWarningScreen';
import { ChooseAmountScreen } from './ChooseAmountScreen/ChooseAmountScreen';
import { ConfirmationScreen } from './ConfirmationScreen/ConfirmationScreen';
import { TwoFactorScreen } from './TwoFactorScreen/TwoFactorScreen';
import { CryptoTransfersSearchBar } from '../CryptoTransfersSearchBar/CryptoTransfersSearchBar';

export interface SendScreenProps {
  state: TTransfersNextStepStateWithActions;
  transfersTranslations: TCryptoTransferTranslations;
  locale: typeof defaultLocale;
  isReadOnlyUser: boolean;
}

const SendScreen: FC<SendScreenProps> = ({
  state,
  transfersTranslations,
  locale,
  isReadOnlyUser,
}) => {
  const { assetsListForSend, isAssetsListLoading } =
    useTransfersAssetsListContext();

  const {
    pageId,
    selectedAssetData,
    selectedNetworkData,
    bindAssetData,
    bindNetworkData,
  } = state;

  const { feature_showCryptoTransfersDrawerSearchBar } = getAppConfig();

  const currentNetworkList = useMemo(() => {
    if (!state.selectedAssetData) return null;

    const networkList = assetsListForSend.find(
      (item) => item.id === state.selectedAssetData?.id,
    )?.networkInformation?.networks;

    return networkList ?? null;
  }, [assetsListForSend, state.selectedAssetData]);

  const hasMultipleNetworks = currentNetworkList
    ? currentNetworkList?.length > 1
    : false;

  return (
    <TransfersLayout
      wrapperProps={{
        'aria-label': 'crypto transfers send flow',
      }}
      topRegion={
        <>
          <TransfersActionBar
            hasMultipleNetworks={hasMultipleNetworks}
            transfersTranslations={transfersTranslations}
            state={state}
          />

          <SendProgressBar state={state} />

          {feature_showCryptoTransfersDrawerSearchBar &&
          pageId === TransfersCommonStepsIds.ChooseAsset ? (
            <CryptoTransfersSearchBar
              disabled={isReadOnlyUser}
              placeholder={transfersTranslations.searchAssets}
            />
          ) : null}
        </>
      }
      middleScrollableRegion={
        isAssetsListLoading ? (
          <AssetsListSkeleton />
        ) : pageId === TransfersCommonStepsIds.ChooseAsset ? (
          <AssetPickerList
            onPick={bindAssetData}
            value={selectedAssetData?.id}
            locale={locale}
            variant={TransfersSectionVariants.Send}
          />
        ) : pageId === TransfersCommonStepsIds.ChooseNetwork &&
          currentNetworkList ? (
          <NetworkPickerList
            networkList={currentNetworkList}
            onNetworkSelected={bindNetworkData}
            selectedNetworkId={selectedNetworkData?.id}
            selectedAssetData={selectedAssetData}
            locale={locale}
            translations={transfersTranslations}
            variant={TransfersSectionVariants.Send}
          />
        ) : pageId === TransfersCommonStepsIds.AssetAndNetworkSummary ? (
          <AssetAndNetworkPage
            assetList={assetsListForSend}
            state={state}
            variant={TransfersSectionVariants.Send}
            translations={transfersTranslations}
          />
        ) : pageId === TransfersSendStepsIds.Warning ? (
          <TransferWarningScreen
            assetList={assetsListForSend}
            state={state}
            translations={transfersTranslations}
          />
        ) : pageId === TransfersSendStepsIds.ChooseRecipient ? (
          <ChooseRecipientScreen
            locale={locale}
            state={state}
            translations={transfersTranslations}
          />
        ) : pageId === TransfersSendStepsIds.MemoMissingWarning ? (
          <MemoIsMissingWarningScreen
            state={state}
            translations={transfersTranslations}
          />
        ) : pageId === TransfersSendStepsIds.ChooseAmount ? (
          <ChooseAmountScreen
            cryptoTransferState={state}
            translations={transfersTranslations}
            currentLocale={locale}
          />
        ) : pageId === TransfersSendStepsIds.Confirmation ? (
          <ConfirmationScreen
            locale={locale}
            state={state}
            translations={transfersTranslations}
          />
        ) : pageId === TransfersSendStepsIds.TwoFactorAuth ? (
          <TwoFactorScreen
            withdrawalId={state?.createdWithdrawalOfferData?.id as string}
            translations={transfersTranslations}
            locale={locale}
          />
        ) : null
      }
    />
  );
};

export { SendScreen };
