'use client';

import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { useComposableModal } from '@bts-web/utils-context';
import { TabBar } from '../../../common/components/TabBar/TabBar';
import { useTransfersState } from '../../utils/useTransfersState';
import { TransfersSectionVariants } from '../../types';
import { DepositScreen } from '../DepositScreen/DepositScreen';
import { SendScreen } from '../SendScreen/SendScreen';
import { TCryptoTransferTranslations } from '../../utils/getCryptoTransferTranslations';
import { useTransfersNextStepState } from '../../utils/useTransfersNextStepState.client';
import { TopActiveSectionTitle } from '../Reusable/TopActiveSectionTitle/TopActiveSectionTitle';
import { useDrawer } from '../../../layouts/Drawer/DrawerContextProvider';
import { useTransfersAssetsListContext } from '../TransfersAssetListContext/TransfersAssetListContext';
import {
  TriggerSetupTwoFaModal,
  UserAccessModal,
  useClientUserInfo,
} from '../../../common';

export interface ITransfersParentProps {
  translations: TCryptoTransferTranslations;
  locale: typeof defaultLocale;
}

const TransfersParent: FC<ITransfersParentProps> = ({
  translations,
  locale,
}) => {
  const { isReadOnlyUser, approved, twoFactorAuthentication } =
    useClientUserInfo();

  const { closeDrawer } = useDrawer();

  const { showModal } = useComposableModal();

  const { currentSection, setCurrentSection } = useTransfersState();

  const { assetsListForDeposit, assetsListForSend } =
    useTransfersAssetsListContext();

  const { nextStepState: depositState } = useTransfersNextStepState({
    closeTransferModule: () => closeDrawer('right'),
    assetList: assetsListForDeposit,
    translations: translations,
  });

  const { nextStepState: sendState } = useTransfersNextStepState({
    closeTransferModule: () => closeDrawer('right'),
    assetList: assetsListForSend,
    translations: translations,
  });

  const bothStatesAreClean =
    depositState.isStateClean && sendState.isStateClean;

  const triggerBusinessApprovedRestrictionModal = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event?.preventDefault();

    event?.stopPropagation();

    if (!approved) {
      showModal(UserAccessModal, {
        type: 'warningTrade',
        onModalClose: () => closeDrawer('right'),
      });

      return;
    }

    return;
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        ...(approved !== true ? { position: 'relative' } : {}),
      })}
    >
      {approved !== true && (
        <div
          data-testid="crypto-transfers-read-only-user-overlay"
          className={css({
            position: 'absolute',
            top: '108px',
            insetInlineStart: 0,
            bottom: 0,
            insetInlineEnd: 0,
            zIndex: 2,
          })}
          onClick={triggerBusinessApprovedRestrictionModal}
        />
      )}

      {bothStatesAreClean && (
        <TabBar
          onValueChange={(newValue) => {
            if (newValue === TransfersSectionVariants.Send) {
              if (twoFactorAuthentication !== 'ENABLED') {
                showModal(TriggerSetupTwoFaModal, {
                  onModalClose: () => {
                    setCurrentSection(TransfersSectionVariants.Deposit);
                  },
                });

                return;
              }

              if (isReadOnlyUser) {
                showModal(UserAccessModal, {
                  type: 'readOnly',
                  onModalClose: () => {
                    setCurrentSection(TransfersSectionVariants.Deposit);
                  },
                });

                return;
              }
            }

            setCurrentSection(newValue as TransfersSectionVariants);
          }}
          value={currentSection}
          tabItems={[
            {
              label: translations.deposit,
              value: TransfersSectionVariants.Deposit,
              disabled: false,
            },
            {
              label: translations.send,
              value: TransfersSectionVariants.Send,
              disabled: false,
            },
          ]}
        />
      )}

      {!depositState.isStateClean ? (
        <TopActiveSectionTitle>{translations.deposit}</TopActiveSectionTitle>
      ) : !sendState.isStateClean ? (
        <TopActiveSectionTitle>{translations.send}</TopActiveSectionTitle>
      ) : null}

      {currentSection === TransfersSectionVariants.Deposit ? (
        <DepositScreen
          state={depositState}
          transfersTranslations={translations}
          locale={locale}
          isReadOnlyUser={isReadOnlyUser}
        />
      ) : (
        <SendScreen
          state={sendState}
          transfersTranslations={translations}
          locale={locale}
          isReadOnlyUser={isReadOnlyUser}
        />
      )}
    </div>
  );
};

export { TransfersParent };
