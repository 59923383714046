import { SkeletonElement } from '../../../../../common';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

const TransferEntityPickerEntrySkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      py: 'small',
      borderBottomWidth: '1px',
      borderColor: 'neutrals.divider',
    })}
  >
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 'small',
      })}
    >
      <SkeletonElement width="56px" height="12px" />

      <div
        className={css({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          gap: 'medium',
        })}
      >
        <SkeletonElement width="24px" height="24px" borderRadius="30px" />

        <div
          className={css({
            display: 'flex',
            flex: '1',
            gap: '2px',
          })}
        >
          <SkeletonElement width="100%" height="24px" />
        </div>
      </div>
    </div>
  </div>
);

export const DepositWalletSkeleton: FC = () => {
  return (
    <div
      data-testid="deposit-wallet-skeleton"
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
      })}
    >
      {/* Asset and Network */}
      <div>
        <TransferEntityPickerEntrySkeleton />
        <TransferEntityPickerEntrySkeleton />
      </div>

      {/* QR Code */}
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          gap: 'medium',
          py: 'extra_large_2',
        })}
      >
        <SkeletonElement width="204px" height="204px" borderRadius="16px" />
      </div>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'medium',
        })}
      >
        {/* Wallet address and creation date */}
        <div
          className={css({
            gap: 'extra_small_2',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: 'extra_small_2',
              })}
            >
              <SkeletonElement width="100px" height="20px" />

              <SkeletonElement width="250px" height="24px" />
            </div>

            <div className={css({ display: 'flex', gap: 'extra_small_2' })}>
              <SkeletonElement width="30px" height="20px" />

              <SkeletonElement width="20px" height="20px" borderRadius="20px" />
            </div>
          </div>

          <SkeletonElement width="120px" height="16px" />
        </div>

        {/* Minimum deposit amount and small deposit fees info */}
        <div className={css({ py: 'extra_small_2' })}>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: 'extra_small_2',
            })}
          >
            <div
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
              })}
            >
              <SkeletonElement height="18px" width="160px" />
              <SkeletonElement height="18px" width="60px" />
            </div>

            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: 'extra_small_3',
              })}
            >
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                })}
              >
                <SkeletonElement height="18px" width="80px" />
                <SkeletonElement height="18px" width="60px" />
              </div>

              <SkeletonElement height="16px" width="180px" />
            </div>
          </div>
        </div>
      </div>

      {/* Pay attention to used wallet address info box */}
      <SkeletonElement width="372px" height="76px" borderRadius="30px" />
    </div>
  );
};
