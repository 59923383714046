/**
 * @generated SignedSource<<a1ad218a9993f2cb733ae8e6a00e3fc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Appropriateness = "APPROPRIATE" | "NOT_APPROPRIATE" | "NOT_EXPERIENCED" | "UNKNOWN";
export type TaxEligibilityStatus = "CONFIRMED" | "UNCONFIRMED";
export type TwoFactorAuthenticationState = "DISABLED" | "ENABLED" | "RESET_IN_PROGRESS" | "SETUP_IN_PROGRESS";
export type UserLanguage = "DE" | "EN" | "ES" | "FR" | "IT" | "PL" | "TR";
export type UserRole = "EXECUTION" | "READ_ONLY";
export type UserFullInfoQuery$variables = {};
export type UserFullInfoQuery$data = {
  readonly me: {
    readonly __typename: string;
    readonly accountLevelId?: string;
    readonly active?: boolean;
    readonly appropriateness?: Appropriateness | null;
    readonly appropriatenessRequiresTestCompletion?: boolean | null;
    readonly appropriatenessRequiresTestResubmission?: boolean | null;
    readonly approved?: boolean;
    readonly blacklisted?: boolean;
    readonly hasTermsNotAccepted: boolean;
    readonly id: string;
    readonly language: UserLanguage;
    readonly notifications: {
      readonly unreadCount: number;
    };
    readonly onboarded: boolean;
    readonly providerActivated?: boolean;
    readonly role?: UserRole;
    readonly taxEligibilityStatus?: TaxEligibilityStatus | null;
    readonly tradingActive?: boolean;
    readonly tradingDisabledReason?: string | null;
    readonly twoFactorAuthentication?: TwoFactorAuthenticationState;
    readonly verificationReason?: string | null;
    readonly verificationStatus?: string;
    readonly verified?: boolean;
    readonly withdrawalsActive?: boolean;
  } | null;
};
export type UserFullInfoQuery = {
  response: UserFullInfoQuery$data;
  variables: UserFullInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tradingActive",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onboarded",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasTermsNotAccepted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNotifications",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unreadCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approved",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "withdrawalsActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "twoFactorAuthentication",
            "storageKey": null
          }
        ],
        "type": "BusinessUser",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appropriateness",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appropriatenessRequiresTestCompletion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appropriatenessRequiresTestResubmission",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blacklisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verified",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradingDisabledReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountLevelId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verificationStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verificationReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "providerActivated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taxEligibilityStatus",
            "storageKey": null
          }
        ],
        "type": "IndividualUser",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserFullInfoQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserFullInfoQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bc25009aa5674892ab0aa0193199223e",
    "id": null,
    "metadata": {},
    "name": "UserFullInfoQuery",
    "operationKind": "query",
    "text": "query UserFullInfoQuery {\n  me {\n    id\n    onboarded\n    language\n    hasTermsNotAccepted\n    __typename\n    notifications {\n      unreadCount\n    }\n    ... on BusinessUser {\n      role\n      approved\n      tradingActive\n      withdrawalsActive\n      twoFactorAuthentication\n    }\n    ... on IndividualUser {\n      appropriateness\n      appropriatenessRequiresTestCompletion\n      appropriatenessRequiresTestResubmission\n      active\n      blacklisted\n      verified\n      tradingActive\n      tradingDisabledReason\n      accountLevelId\n      verificationStatus\n      verificationReason\n      onboarded\n      language\n      providerActivated\n      taxEligibilityStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d771f97a01efbabf801e48ce509d190";

export default node;
