import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import {
  submitButtonWrapper,
  submitButtonWrapperAndButtonProperStyles,
} from '../../utils/styles';
import { PoweredByText } from '../../Reusable/PoweredByText/PoweredByText';
import { PoweredByTextType } from '../../utils';

const VerificationLinkButton: FC<{
  verificationLink: string;
  poweredByText: PoweredByTextType;
  verifyButtonText: string;
  verifyTradeInfoText: string;
}> = ({
  verificationLink,
  poweredByText,
  verifyButtonText,
  verifyTradeInfoText,
}) => {
  return (
    <div className={css(submitButtonWrapper)}>
      <p
        className={css({
          color: 'neutrals.text_primary',
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          letterSpacing: 'body.medium',
          lineHeight: 'body.medium',
          textAlign: 'start',
        })}
        data-testid="verifyTradeInfo"
        aria-label="verify trade info"
      >
        {verifyTradeInfoText}
      </p>

      <PoweredByText {...poweredByText} />

      <div className={submitButtonWrapperAndButtonProperStyles}>
        <a
          href={verificationLink}
          className={css({
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
            gap: 'extra_small_2',
            borderRadius: '8px',
            cursor: 'pointer',
            backgroundColor: 'brand.fill_primary',
            color: 'brand.on_fill_primary',
            '&:active:enabled': {
              backgroundColor: 'brand.fill_pressed',
              color: 'brand.on_fill_primary',
            },
            '&:focus:enabled': {
              backgroundColor: 'brand.fill_pressed',
              color: 'brand.on_fill_primary',
            },
            '&:disabled': {
              backgroundColor: 'neutrals.fill_disabled',
              color: 'neutrals.text_disabled',
            },
            '&:hover:not(:disabled)': {
              backgroundColor: 'neutrals.on_fill_dark_disabled',
            },
            fontSize: 'label.large',
            lineHeight: 'label.large',
            letterSpacing: 'label.large',
            fontWeight: 'label.large',
            px: 'extra_small_2',
            py: 18,
            minHeight: 56,
            width: '100%',
          })}
        >
          {verifyButtonText}
        </a>
      </div>
    </div>
  );
};

export { VerificationLinkButton };
