import { datadogRum } from '@datadog/browser-rum';

export const initDataDogRum = () => {
  // see .gitlab-ci/apps/lbbw.yml for Datadog variables
  const apiUrl = process.env.NEXT_PUBLIC_GQL_API_ENDPOINT_URL;

  const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;

  const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

  const site = process.env.NEXT_PUBLIC_DATADOG_SITE;

  const service = process.env.NEXT_PUBLIC_DATADOG_RUM_SERVICE;

  const env = process.env.NEXT_PUBLIC_DATADOG_ENV;

  const version = process.env.NEXT_PUBLIC_APP_VERSION;

  if (
    applicationId &&
    clientToken &&
    env &&
    apiUrl &&
    version &&
    !isDatadogRumInitialized()
  ) {
    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      env,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      traceSampleRate: 50,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      storeContextsAcrossPages: true,
      allowedTracingUrls: [
        { match: apiUrl, propagatorTypes: ['tracecontext'] },
      ],
    });
  }
};

export const setDataDogUser = (userId: string) => {
  const currentUser = datadogRum.getUser();

  if (currentUser && currentUser.id === userId) {
    return;
  }

  datadogRum.setUser({ id: userId });
};

export const dataDogErrorHelperRoot = (
  ...params: Parameters<typeof datadogRum.addError>
) => {
  datadogRum.addError(...params);
};

export const isDatadogRumInitialized = (): boolean => {
  try {
    const internalContext = datadogRum.getInternalContext();

    return !!internalContext?.session_id;
  } catch (error) {
    console.error('Error checking Datadog RUM initialization:', error);

    return false;
  }
};
