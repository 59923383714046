'use client';

import { useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';

export interface ReadMoreProps {
  internal: {
    className: string;
  };
  external: {
    labels: {
      readMore: string;
      readLess: string;
    };
    text: string;
  };
}

const NR_OF_SHOWN_CHARS = 204;

export function ReadMore({
  className,
  text,
  labels,
}: ReadMoreProps['internal'] & ReadMoreProps['external']) {
  const [isOpen, setIsOpen] = useState(false);

  const hasFewChars = text.length <= NR_OF_SHOWN_CHARS;

  return (
    <Collapsible.Root
      data-testid="read-more"
      className={className}
      onOpenChange={setIsOpen}
    >
      <span>
        {isOpen || hasFewChars ? text : text.slice(0, NR_OF_SHOWN_CHARS)}{' '}
      </span>

      {hasFewChars ? null : (
        <Collapsible.Trigger>
          <span>{isOpen ? labels.readLess : labels.readMore}</span>
        </Collapsible.Trigger>
      )}
    </Collapsible.Root>
  );
}

export default ReadMore;
