import { TFunction } from 'i18next';

export type TCryptoTransferTranslations = TActionBarTranslations & {
  deposit: string;
  send: string;
  memo: string;
  memoMissingButton: string;
  memoEmpty: string;
  destination_tag: string;
  message: string;
  searchAssets: string;
  copy: string;
  walletAddress: string;
  successToastAddressTitle: string;
  addressCopiedFailed: string;
  fees: string;
  minimumDepositAmount: string;
  cryptoWithdrawalAddressWarning: string;
  cryptoWithdrawalCtaCancel: string;
  cryptoWithdrawalCtaConfirm: string;
  cryptoWithdrawalFraudWarning: string;
  cryptoWithdrawalLossWarning: string;
  cryptoWithdrawaNetworkWarning: string;
  cryptoWithdrawalSubtitle: string;
  cryptoWithdrawalTitle: string;
  cryptoWithdrawalRecipientErrorTitle: string;
  cryptoWithdrawalRecipientErrorSubtitle: string;
  cryptoWithdrawalMemoWarning: string;
  cryptoWithdrawalMemoMissingText: string;
  cryptoWithdrawalMemoErrorTitle: string;
  cryptoWithdrawalMemoErrorSubtitle: string;
  units: string;
  fiat: string;
  generalErrorHeadline: string;
  inlineInfoCryptoTransferNewWalletTitle: string;
  total: string;
  wallet: string;
  transfer: string;
  address: string;
  externalWallet: string;
  transactionFees: string;
  continue: string;
  cryptoWithdrawalConfirmationOnNetwork: string;
  cryptoWithdrawalConfirmationToRecipient: string;
  cryptoWithdrawalConfirmationText: string;
  cryptoWithdrawal2FATitle: string;
  cryptoWithdrawal2FASubtitle: string;
  cryptoWithdrawal2FAInvalidCode: string;
  done: string;
  cryptoWithdrawalSuccessBody: string;
  cryptoWithdrawalSuccessSubtitle: string;
  savedWallets: string;
  goToPortfolio: string;
  inlineInfoFirstCryptoTransferTitle: string;
  cryptoDepositDrawerTitle: string;
  cryptoDepositDrawerSubtitle: string;
  iUnderstand: string;
};

export type TActionBarTranslations = {
  selectAsset: string;
  chooseRecipient: string;
  chooseAmount: string;
  summary: string;
  cryptoWithdrawalMemoIsMissingTitle: string;
  selectNetwork: string;
  enterMemo: string;
  assetAndNetwork: string;
  toast_info_one_network_subtitle: string;
  toast_info_one_network_title: string;
  inline_warning_asset_network_lost: string;
  cryptocurrency: string;
  network: string;
  view_wallet_address_button: string;
  next: string;
};

export const cryptoTransferTranslationsMap: TCryptoTransferTranslations = {
  chooseAmount: 'choose_amount',
  chooseRecipient: 'choose_recipient',
  deposit: 'deposit',
  memo: 'memo',
  memoMissingButton: 'memo_drawer_button',
  memoEmpty: 'memo_empty',
  destination_tag: 'destination_tag',
  message: 'message',
  cryptoWithdrawalMemoIsMissingTitle: 'memo_missing_drawer_title',
  selectAsset: 'choose_asset',
  selectNetwork: 'choose_network',
  searchAssets: 'search_assets',
  send: 'send',
  summary: 'summary',
  assetAndNetwork: 'deposit_asset_and_network',
  enterMemo: 'enter_memo',
  copy: 'copy',
  walletAddress: 'wallet_address',
  successToastAddressTitle: 'success_toast_address_title',
  addressCopiedFailed: 'something_went_wrong',
  toast_info_one_network_subtitle: 'toast_info_one_network_subtitle',
  toast_info_one_network_title: 'toast_info_one_network_title',
  inline_warning_asset_network_lost: 'inline_warning_asset_network_lost',
  cryptocurrency: 'deposit_choose_asset_crypto',
  network: 'deposit_choose_asset_network',
  view_wallet_address_button: 'view_wallet_address_button',
  next: 'next',
  fees: 'fees',
  minimumDepositAmount: 'minimum_deposit_amount',
  cryptoWithdrawalAddressWarning: 'crypto_withdrawal_address_warning',
  cryptoWithdrawalCtaCancel: 'crypto_withdrawal_cta_cancel',
  cryptoWithdrawalCtaConfirm: 'crypto_withdrawal_cta_confirm',
  cryptoWithdrawalFraudWarning: 'crypto_withdrawal_fraud_warning',
  cryptoWithdrawalLossWarning: 'crypto_withdrawal_loss_warning',
  cryptoWithdrawaNetworkWarning: 'crypto_withdrawal_network_warning',
  cryptoWithdrawalSubtitle: 'crypto_withdrawal_subtitle',
  cryptoWithdrawalTitle: 'crypto_withdrawal_title',
  cryptoWithdrawalRecipientErrorTitle:
    'crypto_withdrawal_choose_recipient_error_title',
  cryptoWithdrawalRecipientErrorSubtitle:
    'crypto_withdrawal_choose_recipient_error_subtitle',
  cryptoWithdrawalMemoWarning: 'crypto_withdrawal_memo_warning',
  cryptoWithdrawalMemoMissingText: 'memo_missing_drawer_subtitle',
  cryptoWithdrawalMemoErrorTitle: 'crypto_withdrawal_memo_error_toast_title',
  cryptoWithdrawalMemoErrorSubtitle:
    'crypto_withdrawal_memo_error_toast_subtitle',
  generalErrorHeadline: 'general_error_headline',
  fiat: 'eur_label',
  units: 'units',
  inlineInfoCryptoTransferNewWalletTitle:
    'inline_info_crypto_transfer_new_wallet_title',
  total: 'total',
  wallet: 'wallet',
  transfer: 'transfer_button',
  address: 'address',
  externalWallet: 'external_wallet',
  transactionFees: 'transaction_fees',
  continue: 'continue',
  cryptoWithdrawalConfirmationOnNetwork: 'crypto_withdrawal_success_on_network',
  cryptoWithdrawalConfirmationToRecipient:
    'crypto_withdrawal_success_to_recipient',
  cryptoWithdrawalConfirmationText: 'crypto_withdrawal_confirmation_text',
  cryptoWithdrawal2FATitle: 'crypto_withdrawal_2fa_title',
  cryptoWithdrawal2FASubtitle: 'crypto_withdrawal_2fa_subtitle',
  cryptoWithdrawal2FAInvalidCode: 'crypto_withdrawal_2fa_invalid_code',
  done: 'done',
  cryptoWithdrawalSuccessBody: 'crypto_withdrawal_success_body',
  cryptoWithdrawalSuccessSubtitle: 'crypto_withdrawal_success_subtitle',
  savedWallets: 'saved_wallets',
  goToPortfolio: 'go_to_portfolio',
  inlineInfoFirstCryptoTransferTitle: 'inline_info_first_crypto_transfer_title',
  cryptoDepositDrawerTitle: 'crypto_deposit_drawer_title',
  cryptoDepositDrawerSubtitle: 'crypto_deposit_drawer_subtitle',
  iUnderstand: 'I_understand',
};

export const getCryptoTransferTranslations = (
  transUtil: TFunction,
): TCryptoTransferTranslations => {
  return Object.entries(cryptoTransferTranslationsMap).reduce(
    (acc, [key, value]) => {
      acc[key as keyof TCryptoTransferTranslations] = transUtil(value);

      return acc;
    },
    {} as TCryptoTransferTranslations,
  );
};
