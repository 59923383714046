import { css } from '@bts-web/utils-style-engine';
import { TradeBoxSkeleton } from '../../AssetPageInitiateTradeBox/TradeBoxSkeleton';
import { SetUpSavingsPlanBoxSkeleton } from '../../TradeParent';
import { PageSkeletonWrapper } from '../../../common/components/loading/PageSkeletonWrapper/PageSkeletonWrapper';
import { SkeletonElement } from '../../../common';
import { HiddenMobile } from '../../../layouts';

export const TradeSkeletonWithSavings = () => (
  <PageSkeletonWrapper>
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
      })}
    >
      <HiddenMobile>
        <div
          className={css({
            display: 'flex',
            pt: 'small',
            paddingInlineStart: 'medium',
            gap: 'extra_small_2',
          })}
        >
          <SkeletonElement width="48" height="48" borderRadius="50%" />

          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: 'extra_small_3',
            })}
          >
            <SkeletonElement width="90px" height="30px" />
            <SkeletonElement width="30px" height="18px" />
          </div>
        </div>
      </HiddenMobile>

      <TradeBoxSkeleton withNav>
        <SetUpSavingsPlanBoxSkeleton />
      </TradeBoxSkeleton>
    </div>
  </PageSkeletonWrapper>
);
