import Image from 'next/image';
import Link from 'next/link';
import { css } from '@bts-web/utils-style-engine';
import BellIcon from './bell.svg';

export const NotificationsBell = ({
  href,
  count,
}: {
  href: string;
  count: number;
}) => {
  return (
    <Link
      href={href}
      role="button"
      aria-label="view notifications"
      className={css({
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
      })}
    >
      <Image
        width={22}
        height={22}
        data-testid="bell-icon"
        alt="bell icon"
        src={BellIcon}
      />

      {count > 0 && (
        <span
          data-testid="notifications-number"
          className={css({
            position: 'absolute',
            top: '-3px',
            insetInlineEnd: '-2px',
            background: '#FF5252',
            borderRadius: '50%',
            color: 'white',
            width: '16px',
            height: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 0 4px rgba(0,0,0,0.2)',
            fontSize: 'caption.small',
            letterSpacing: 'caption.small',
            lineHeight: 'caption.small',
            fontWeight: 'caption.small',
          })}
        >
          {count}
        </span>
      )}
    </Link>
  );
};
