import { AppConfig } from './types';

export const APPLICATION_CONFIGS: AppConfig[] = [
  {
    appId: 1,
    appName: 'Raiffeisen',
    feature_hasCryptoTransfersEnabled: false,
    feature_showCryptoTransfersDrawerSearchBar: false,
    feature_hasSavingsPlansEnabled: true,
    feature_showNotifications: true,
    feature_isPaymentMethodVisibleOnAccept: false,
  },
  {
    appId: 2,
    appName: 'LBBW',
    additionalBuyConfirmationText: 'lbbw_buy_success_info',
    additionalSellConfirmationText: 'lbbw_sell_success_info',
    localPublicFolderPathTo_FAQDocument: '/LBBW-CryptoApp-FAQ-document.pdf',
    URLforTermsPage: 'http://www.bitpanda.com/de/tech/lbbw-product-terms',
    URLforCustomerSupport:
      'https://lbbwsupport.bitpanda.com/hc/de/requests/new',
    feature_usesSmallDiscoverHeader: true,
    feature_hasCryptoTransfersEnabled: true,
    feature_showCryptoTransfersDrawerSearchBar: true,
    feature_showNotifications: true,
    feature_isPaymentMethodVisibleOnAccept: true,
  },
  {
    appId: 3,
    appName: 'Rakbank',
    feature_hasCryptoTransfersEnabled: true,
    feature_showCryptoTransfersDrawerSearchBar: true,
    feature_hasSavingsPlansEnabled: false,
    feature_showNotifications: true,
    feature_isPaymentMethodVisibleOnAccept: true,
  },
];
