import { FC } from 'react';
import {
  getPrecisionFloatValue,
  convertToLocaleDateString,
} from '@bts-web/utils-formatting';
import {
  getLocale,
  Language,
  useClientTranslation,
} from '@bts-web/utils-lokalise';
import { css } from '@bts-web/utils-style-engine';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import { TransfersCommonStepsIds } from '../../../../types';
import { RedirectToAssetSelection } from '../redirects/RedirectToAssetSelection';
import { RedirectToNetworkSelection } from '../redirects/RedirectToNetworkSelection';
import { AlertBox, QRCode } from '../../../../../common';
import { TransferEntityPickerEntry } from '../../../Reusable/TransferEntityPickerEntry/TransferEntityPickerEntry';
import { CopyToClipboard } from '../../../../../common/components/CopyToClipboard/CopyToClipboard';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';
import { DepositInfoField } from './DepositInfoField';
import { useCryptoAddress } from '../../hooks/useCryptoAddress';
import { DepositWalletSkeleton } from './DepositWalletSkeleton';

const dateStyles = css({
  fontSize: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
  fontWeight: 'caption.small',
  color: 'neutrals.text_secondary',
});

interface DepositWalletPageProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const DepositWalletPage: FC<DepositWalletPageProps> = ({
  state,
  translations,
}) => {
  const { i18n, t } = useClientTranslation();

  const currentLocale = getLocale(i18n.resolvedLanguage as Language);

  const { selectedAssetData, selectedNetworkData } = state;

  const { isDataLoading, cryptoAddress } = useCryptoAddress({
    translations: {
      title: t('inline_error_tranasaction_fail'),
      subtitle: t('info_toast_asset_unavailable_subtitle'),
    },
    coinNetworkId: selectedNetworkData?.coinNetworkId,
  });

  if (!selectedAssetData) {
    return <RedirectToAssetSelection state={state} />;
  }

  if (!selectedNetworkData) {
    return <RedirectToNetworkSelection state={state} />;
  }

  if (isDataLoading) {
    return <DepositWalletSkeleton />;
  }

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
      })}
      data-testid="deposit-wallet-address-info"
    >
      <div>
        <TransferEntityPickerEntry
          title={selectedAssetData.name ?? ''}
          type="pickerParentWithValue"
          imageUrl={selectedAssetData.logoUrl ?? undefined}
          onPick={() =>
            state.changeToPageAndBindActions({
              pageId: TransfersCommonStepsIds.ChooseAsset,
              newBackAction: {
                pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
                type: 'page',
              },
            })
          }
          heading={translations.cryptocurrency}
        />

        <TransferEntityPickerEntry
          title={selectedNetworkData.name ?? ''}
          type="pickerParentWithValue"
          imageUrl={selectedNetworkData.logoUrl ?? undefined}
          onPick={() =>
            state.changeToPageAndBindActions({
              pageId: TransfersCommonStepsIds.ChooseNetwork,
              newBackAction: {
                pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
                type: 'page',
              },
            })
          }
          heading={translations.network}
        />
      </div>

      {cryptoAddress ? (
        <div>
          <QRCode
            value={cryptoAddress.address}
            logoImageUrl={selectedAssetData.logoUrl ?? undefined}
          />

          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: 'medium',
            })}
          >
            <div
              className={css({
                gap: 'extra_small_2',
                display: 'flex',
                flexDirection: 'column',
              })}
            >
              <div
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                })}
              >
                <div
                  className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    paddingInlineEnd: 'small',
                    gap: 'extra_small_2',
                  })}
                >
                  <span
                    aria-label="wallet address label"
                    className={css({
                      fontSize: 'label.medium_medium',
                      lineHeight: 'label.medium_medium',
                      letterSpacing: 'label.medium_medium',
                      fontWeight: 'label.medium_medium',
                      color: 'neutrals.text_secondary',
                    })}
                  >
                    {translations.walletAddress}
                  </span>

                  <div
                    className={css({
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    })}
                  >
                    {cryptoAddress.address}
                  </div>
                </div>

                <CopyToClipboard
                  translations={{
                    copy: translations.copy,
                    successToastAddressTitle:
                      translations.successToastAddressTitle,
                    addressCopiedFailed: translations.addressCopiedFailed,
                  }}
                  value={cryptoAddress.address}
                />
              </div>

              <div className={dateStyles}>
                {t('created_on_date', {
                  date: convertToLocaleDateString(
                    cryptoAddress.createdAt,
                    currentLocale,
                  ),
                })}
              </div>
            </div>

            <div className={css({ py: 'extra_small_2' })}>
              {selectedNetworkData.minDepositThreshold ? (
                <DepositInfoField
                  value={`${getPrecisionFloatValue(selectedNetworkData.minDepositThreshold)} ${selectedAssetData.symbol}`}
                  label={translations.minimumDepositAmount}
                />
              ) : null}

              {selectedNetworkData.smallDepositThreshold &&
              selectedNetworkData.smallDepositFee ? (
                <DepositInfoField
                  value={`${getPrecisionFloatValue(selectedNetworkData.smallDepositFee)} ${selectedAssetData.symbol}`}
                  label={translations.fees}
                  caption={t('on_deposits_below', {
                    assetamount: `${getPrecisionFloatValue(
                      selectedNetworkData.smallDepositThreshold,
                    )} ${selectedAssetData.symbol}`,
                  })}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      <AlertBox variant="info">
        {translations.inlineInfoFirstCryptoTransferTitle}
      </AlertBox>
    </div>
  );
};

export { DepositWalletPage };
