import { useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { useComposableModal } from '@bts-web/utils-context';
import { Locale, useClientTranslation } from '@bts-web/utils-lokalise';
import { translateErrorCodesOrReturnThemDirectly } from '@bts-web/utils-relay';
import { DigitCodeInput, ButtonBase, FailureModal } from '../../../../common';
import { acceptCryptoWithdrawalOffer } from '../actions/acceptCryptoWithdrawalOffer.action';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { useDrawer } from '../../../../layouts/Drawer/DrawerContextProvider';
import { finishWithdraw } from '../actions/finishWithdraw.action';
import { datadogErrorHelper } from '../../../../common/utils/datadogErrorHelper';
import { WithdrawalSuccessModal } from '../components/WithdrawalSuccessModal/WithdrawalSuccessModal';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  height: '100%',
});

const titleStyle = css({
  color: 'neutrals.text_primary',
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
});

const subtitleStyle = css({
  color: 'neutrals.text_secondary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
});

const inputWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const errorStyle = css({
  color: 'negative.stroke_primary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
});

interface TwoFactorScreenProps {
  translations: TCryptoTransferTranslations;
  withdrawalId: string;
  locale: Locale;
}

const TwoFactorScreen = ({
  locale,
  withdrawalId,
  translations,
}: TwoFactorScreenProps) => {
  const { showModal } = useComposableModal();

  const { closeDrawer } = useDrawer();

  const { t } = useClientTranslation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [finalCode, setFinalCode] = useState('');

  const [hasError, setHasError] = useState(false);

  const onChangeInput = (isFilled: boolean, code: string) => {
    setIsButtonDisabled(!isFilled);

    if (isFilled) {
      setFinalCode(code);
    } else {
      setHasError(false);
    }
  };

  const onSubmitCode = () => {
    setIsButtonLoading(true);

    acceptCryptoWithdrawalOffer({
      code: finalCode,
      withdrawalId,
    })
      .then(async (response) => {
        setIsButtonLoading(false);

        closeDrawer('right');

        if (response.errors) {
          const { genericTitle, translatedCodes } =
            await translateErrorCodesOrReturnThemDirectly(response.errors);

          showModal(FailureModal, {
            title: genericTitle,
            description: translatedCodes.join(', '),
            tryAgainLink: {
              label: translations.goToPortfolio,
              url: '/assets/portfolio',
            },
          });

          return;
        }

        const { acceptCryptoWithdrawal } = response.data;

        showModal(WithdrawalSuccessModal, {
          currentLocale: locale,
          withdrawalData: {
            asset: acceptCryptoWithdrawal?.asset,
            assetAmount: acceptCryptoWithdrawal?.assetAmount,
            fiatAmount: acceptCryptoWithdrawal?.fiatAmount,
          },
        });
      })
      .catch((error: Error) => {
        setIsButtonLoading(false);

        closeDrawer('right');

        showModal(FailureModal, {
          title: t('inline_error_tranasaction_fail'),
          description: `[MISSING TRANSLATION] We are actively monitoring these types of errors and will take steps to prevent similar occurrences in the future.`,
          tryAgainLink: {
            label: translations.goToPortfolio,
            url: '/assets/portfolio',
          },
        });

        datadogErrorHelper({
          errorMessage: `[ERR][CRYPTO WITHDRAWAL 2FA SCREEN AFTER CONFIRM PRESSED] ${error.message}`,
          errorSeverity: DatadogErrorLevels.CRITICAL,
        });
      })
      .finally(() => {
        finishWithdraw();
      });
  };

  return (
    <div className={containerStyle}>
      <span className={titleStyle}>
        {translations.cryptoWithdrawal2FATitle}
      </span>

      <span className={subtitleStyle}>
        {translations.cryptoWithdrawal2FASubtitle}
      </span>

      <div className={inputWrapper}>
        <DigitCodeInput hasError={hasError} onChange={onChangeInput} />

        {hasError && (
          <span className={errorStyle}>
            {translations.cryptoWithdrawal2FAInvalidCode}
          </span>
        )}
      </div>

      <div
        className={css({
          marginTop: 'auto',
          display: 'flex',
          justifyContent: 'center',
        })}
      >
        <ButtonBase
          isLoading={isButtonLoading}
          disabled={isButtonDisabled || isButtonLoading}
          onClick={onSubmitCode}
        >
          {translations.continue}
        </ButtonBase>
      </div>
    </div>
  );
};

export { TwoFactorScreen };
