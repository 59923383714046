'use client';

import { FC, useMemo } from 'react';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { getAppConfig } from '@bts-web/core-app-config';
import { TTransfersNextStepStateWithActions } from '../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../utils/getCryptoTransferTranslations';
import { TransfersActionBar } from '../Reusable/ActionBar/TransfersActionBar';
import { AssetPickerList } from '../Reusable/AssetPickerList/AssetPickerList';
import { NetworkPickerList } from '../Reusable/NetworkPickerList/NetworkPickerList';
import { AssetAndNetworkPage } from '../Reusable/AssetAndNetworkPage/AssetAndNetworkPage';
import { TransfersLayout } from '../Reusable/TransfersLayout/TransfersLayout';
import { DepositProgressBar } from './components/DepositProgressBar/DepositProgressBar';
import { DepositWalletPage } from './components/DepositWalletPage/DepositWalletPage';
import { DepositWarningPage } from './components/DepositWarningPage/DepositWarningPage';
import { useTransfersAssetsListContext } from '../TransfersAssetListContext/TransfersAssetListContext';
import { AssetsListSkeleton } from '../TransfersParent/TransfersSkeleton';
import {
  TransfersCommonStepsIds,
  TransfersDepositStepsIds,
  TransfersSectionVariants,
} from '../../types';
import { CryptoTransfersSearchBar } from '../CryptoTransfersSearchBar/CryptoTransfersSearchBar';

export type DepositScreenProps = {
  state: TTransfersNextStepStateWithActions;
  transfersTranslations: TCryptoTransferTranslations;
  locale: typeof defaultLocale;
  isReadOnlyUser: boolean;
};

const DepositScreen: FC<DepositScreenProps> = ({
  state,
  transfersTranslations,
  locale,
  isReadOnlyUser,
}) => {
  const { assetsListForDeposit, isAssetsListLoading } =
    useTransfersAssetsListContext();

  const {
    pageId,
    selectedAssetData,
    selectedNetworkData,
    bindAssetData,
    bindNetworkData,
  } = state;

  const { feature_showCryptoTransfersDrawerSearchBar } = getAppConfig();

  const currentNetworkList = useMemo(() => {
    if (!selectedAssetData) return null;

    const networkList = assetsListForDeposit.find(
      (item) => item.id === selectedAssetData?.id,
    )?.networkInformation?.networks;

    return networkList ?? null;
  }, [assetsListForDeposit, selectedAssetData]);

  const hasMultipleNetworks = currentNetworkList
    ? currentNetworkList?.length > 1
    : false;

  return (
    <TransfersLayout
      wrapperProps={{
        'aria-label': 'crypto transfers deposit flow',
      }}
      topRegion={
        <>
          <TransfersActionBar
            transfersTranslations={transfersTranslations}
            state={state}
            hasMultipleNetworks={hasMultipleNetworks}
          />

          <DepositProgressBar state={state} />

          {feature_showCryptoTransfersDrawerSearchBar &&
          pageId === TransfersCommonStepsIds.ChooseAsset ? (
            <CryptoTransfersSearchBar
              placeholder={transfersTranslations.searchAssets}
              disabled={isReadOnlyUser}
            />
          ) : null}
        </>
      }
      middleScrollableRegion={
        isAssetsListLoading ? (
          <AssetsListSkeleton />
        ) : pageId === TransfersCommonStepsIds.ChooseAsset ? (
          <AssetPickerList
            onPick={bindAssetData}
            value={selectedAssetData?.id}
            locale={locale}
            variant={TransfersSectionVariants.Deposit}
          />
        ) : pageId === TransfersCommonStepsIds.ChooseNetwork &&
          currentNetworkList ? (
          <NetworkPickerList
            selectedAssetData={selectedAssetData}
            networkList={currentNetworkList}
            onNetworkSelected={bindNetworkData}
            selectedNetworkId={selectedNetworkData?.id}
            locale={locale}
            translations={transfersTranslations}
            variant={TransfersSectionVariants.Deposit}
          />
        ) : pageId === TransfersCommonStepsIds.AssetAndNetworkSummary ? (
          <AssetAndNetworkPage
            assetList={assetsListForDeposit}
            state={state}
            variant={TransfersSectionVariants.Deposit}
            translations={transfersTranslations}
          />
        ) : pageId === TransfersDepositStepsIds.DepositWarning ? (
          <DepositWarningPage
            state={state}
            translations={transfersTranslations}
          />
        ) : pageId === TransfersDepositStepsIds.DepositWallet ? (
          <DepositWalletPage
            state={state}
            translations={transfersTranslations}
          />
        ) : null
      }
    />
  );
};

export { DepositScreen };
